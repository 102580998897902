
import Vue from 'vue'
import AppBar from "@/components/UI/AppBar/AppBar.vue"
import Footer from '@/components/Footer/Footer.vue'
import HeaderBar from "@/components/UI/HeaderBar/UIHeaderBar.vue"
import { Route } from 'vue-router';
import { RouteInformation, RouteMetaInterface } from '@/routerManagerNew/routes'
import { mapState } from 'vuex';

export default Vue.extend({
    components: {
        "app-bar": AppBar,
        "custom-footer": Footer, 
        "header-bar": HeaderBar,     
    },

    data: () => ({
        isLoading: false as  boolean
    }),

    computed: {

        ...mapState("partner", ["appDataContent", "partnerSettings"]),
        ...mapState("ui", ["isMobileViewDisplayed"]),
        ...mapState("product", ["isProductDetailsLoading"]),
        ...mapState("store", ["selectedStoreDetails"]),
        
        pageTitle(): string {
            var routeName = this.route.name;            
            var pageTitle = "";

            switch(routeName) {
                case RouteInformation.Checkout: 
                    pageTitle = "Checkout";
                    break;

                case RouteInformation.OrdersPage: 
                    pageTitle = "Orders";
                    break;

                case RouteInformation.OrderSummaryPage: 
                    pageTitle =  `#${this.route.params["orderId"]}`;
                    break;

                case RouteInformation.ProfilePage: 
                    pageTitle =  "Account Details";
                    break;

                case RouteInformation.WalletPage: 
                    pageTitle =  "Wallet";
                    break;

                case RouteInformation.LoyaltyPage: 
                    pageTitle =  (this as any).appDataContent.loyaltyProgramTitleText.replace('{1}', (this as any).partnerSettings.partnerName);
                    break;

                case RouteInformation.ContactUsPage: 
                    pageTitle =  "Contact Us";
                    break;
            }

            return pageTitle;


        },

        whatsappPhoneNumber(): string | null {
            if(this.selectedStoreDetails.id > 0 && this.selectedStoreDetails.whatsAppNumbers.length > 0)
            {
                return this.selectedStoreDetails.whatsAppNumbers[0];
            }

            else if(this.partnerSettings.customerServiceWhatsAppNumber.length > 0) 
            {
                return this.partnerSettings.customerServiceWhatsAppNumber
            }
            else return null;
        },

        // get the route
        route(): Route {
            return this.$route;
        },

        // check if route has app bar
        isAppBarDisplayed(): boolean{            
            if(this.isMobileViewDisplayed == true && (this.$route.name == "storesPage" ||this.$route.name == "storeHomePage")) {
                return true;
            }

            return (this.route.meta as RouteMetaInterface).includeAppBar;
        },

        // check if route has light app bar
        isAppBarLight(): boolean{
            return (this.route.meta as RouteMetaInterface).hasLightAppBar;
        },

        // check if footer is displayed
        isFooterDisplayed(): boolean{
            return (this.route.meta as RouteMetaInterface).includeFooter;
        }
    },

    methods: {
        updateLoadingState(val: boolean) {
            this.isLoading = val;
        }
    },

    watch: {
        isProductDetailsLoading(val: boolean) {
            this.updateLoadingState(val)
        } 
    }
    
})
