
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
    computed: {
        ...mapState("partner", ["partnerSettings", "appDataContent"]),
        ...mapState('ui', ["isMobileViewDisplayed"]),
        ...mapState('store', ["selectedStoreDetails"]),

        whatsappPhoneNumber(): string | null {
            if(this.selectedStoreDetails.id > 0 && this.selectedStoreDetails.whatsAppNumbers.length > 0)
            {
                return this.selectedStoreDetails.whatsAppNumbers[0];
            }

            else if(this.partnerSettings.customerServiceWhatsAppNumber.length > 0) 
            {
                return this.partnerSettings.customerServiceWhatsAppNumber
            }
            else return null;
        },

        
    }
})
