export default class AppDataContent {

    // ========================================================
    // Utilities Section
    // ========================================================

    // App version upgrade Page
    obsoleteBuildHeadingContentTxt: string = "";
    obsoleteSubHeadingContentTxt: string = "";
    outdatedSubHeadingContentTxt: string = "";
    obsoleteBuildActionTxtBtn: string = "";

    // Maintenance App Page
    maintenanceHeadingContentTxt: string = "";
    maintenanceSubHeadingContentTxt: string = "";
    maintenanceActionTxtBtn: string = "";

    // Empty List Of Items View Page
    emptySubcatHeadingContentTxt: string = "";
    emptySubcatSubHeadingContentTxt: string = "";
    emptySubcatActionTxtBtn: string = "";
    emptyPromotionSubHeading1: string = "";
    emptyPromotionSubHeading2: string = "";
    emptyFavoriteItemsHeadingContentTxt: string = "";
    emptyFastShoppingItemsHeadingContentTxt: string = "";
    emptyFavoriteItemsSubHeadingContentTxt: string = "";
    emptyFastShoppingItemsSubHeadingContentTxt: string = "";
    emptySearchText: string = "";

    // Empty orders and Fast shopping not logged in
    orderListEmptyOrderListNotLoggedInTxt: string = "";
    fastShoppingEmptyViewNotLoggedInTxt: string = "";

    // Empty View Online Card Payment
    emptyViewOnlineCardPaymentSubheading: string = "";
    emptyViewStripeOnlinePaymentButton: string = "";
   
    // Welcome Page
    authWlcPartnerMsgTxt: string = ""; 
    authWlcCreateNewAccountTxtBtn: string = "";
    authWlcLoginWithFacebookTxtBtn: string = "";
    authWlcLoginWithYourAccountTxtBtn: string = "";
    authWlcFeatureNotYetDoneTxtBnr: string = "";
    authWlcOrTxt: string = "";
    skipAppBarBtn: string = "";
    closeAppBarBtn: string ="";
    welcomeAuthLoginBtn: string = "";
    welcomeAuthRegisterBtn: string = "";
    welcomeAuthAgreementMsg1: string = "";
    welcomeAuthAgreementMsg2: string = "";
    welcomeAuthPartnerSpecificIntoMsg: string = "";

    // Welcome Page
    welcomeMsgTxt: string = ""; // Hi USER, ...
    welcomeGuidanceMsgTxt: string = ""; // Set location to explore...
    welcomeDeliveryLocationTxtBtn: string = ""; //Get location via GPS
    welcomeLocationTermsMsgTxt: string = ""; // Terms of usage of your location data
    currentLocationButton: string = "";
    manuallyLocationButton: string = "";
    googlePlacesSearchGuidanceEmptyView: string = "";
    googlePlacesSearchNoResultEmptyView: string = "";
    
    // Login Page   
    authLoginFailureTxtBnr: string = "";
    authLoginBarTitle: string = "";
    authLoginTxtBtn: string = "";
    authLoginForgotPwdTxtBtn: string = "";
    authLoginEmptyMobileNbreTxtBnr: string = "";
    authLoginEmptyPwdTxtBnr: string = "";
    authLoginContactUsMessage: string = "";
    loginPageLoginFailedDialogTitle: string = "";
    loginPageLoginFailedDialogMsg: string = "";
    loginPageLoginFailedDialogContactUsBtn: string = "";
    loginPageLoginFailedDialogCancelBtn: string = "";
    loginPageLoginAppBarTitle: string = "";

    // Custom Widgets Auth Page
    nextTxtBtn: string = "";
    errorLowerThanSpecificValueTxtField1: string = "";
    errorLowerThanSpecificValueTxtField2: string = "";
    errorIsNotAlphaTxtField: string = "";
    errorMobileNbrLengthTxtField1: string = "";
    errorMobileNbrLengthTxtField2: string = "";
    errorMobileNbrIsNotNumericTxtField: string = "";
    mobileNbrTxtField: string = "";
    mobilePrefixTxtField: string = "";
    registerMessageAndButtonMsg: string = "";
    registerMessageAndButtonBtn: string = "";
    loginMessageAndButtonMsg: string = "";
    loginMessageAndButtonBtn: string = "";
    authInvalidMobileNumberMsg: string = "";

    // Register Name Page
    authRegNameBarTitle: string = "";
    authRegNamefirstnameTxtField: string = "";
    authRegNamelastnameTxtField: string = "";
    authRegNameGenderTxtField: string = "";
    authRegNameDateOfBirthTxtField: string = "";
    authRegNameFirstNameErrorMessage1: string = "";
    authRegNameFirstNameErrorMessage2: string = "";
    authRegNameLastNameErrorMessage1: string = "";
    authRegNameLastNameErrorMessage2: string = "";
    authRegNameNextBtn: string = "";

    // Register Validate Page
    authRegValidateExceededSmSRetriesTxt: string = "";
    authRegValidateEditPhoneNbSmsTxtBtn: string = "";
    authRegValidateResendSmsTxtBtn: string = "";
    authRegValidateCnxServerPbTxtBnr: string = "";
    authRegValidateTimerToResendSmsTxt: string = "";
    authRegValidatePswdsuccessfullyChangedTxt: string = "";
    authRegValidatePhoneCodeForgotPwdBarTitle: string = "";
    authRegValidateSmsSentSubTitleForgotPwdTxt: string = "";
    authRegValidatePhoneValidationBarTitle: string = "";
    authRegValidatephoneCodeValidationSubTitlePart1Txt: string = "";
    authRegValidatephoneCodeValidationSubTitlePart2Txt: string = "";
    authRegValidateInfoIssueMsgTxt: string = "";
    authRegValidateErrorRenewalPwdSnackBar: string = "";
    authRegValidateIssueValidatingNewAccountSnackBar: string = "";
    authRegValidateCnxServerPbSnackBar: string = "";
    authRegValidateErrorIncorrectCodeSnackBar: string = "";
    authRegValidateErrorRceiverNullSnackBar: string = "";
    authRegValidateContactUsMessage: string = "";

    // Forgot Password Mobile Page
    authForgotPwdBarTitle: string = "";
    authForgotPwdSubTitleMobileNbreUsedTxt: string = "";
    authForgotPwdSubTitleNewPwdTxt: string = "";
    authForgotPwdNewPwdTxtField: string = "";
    authForgotPwdEmptyMobileNbreTxtBnr: string = "";
    authForgotPwdMobileNbreLenghIncorrectTxtBnr: string = "";
    authForgotPwdLenghPwdIncorrecrTxtBnr: string = "";
    authForgotPwdCnxServerPbTxtBnr: string = "";
    authChangePwdBarTitle: string =  "";
    authForgotPwdSubmitBtn: string =  "";

    // User Gender
    userGenderNoneStr: string = "";
    userGenderMaleStr: string = "";
    userGenderFemaleStr: string = "";

    
    // Register Age Gender Page
    authRegAgeGenderBarTitle: string = "";
    authRegAgeGenderAgeTxtField: string = "";

    // Register Number Page
    authRegNbrBarTitle: string = "";
    nbrePwdTxtField: string = ""; // used in 2 pages : Login page
    errorPwdLengthTxtField1: string = "";  // used in 4 pages : Content.dart, Login & ForgotPwd page
    errorPwdLengthTxtField2: string = "";  // used in 4 pages : Content.dart, Login & ForgotPwd page
    errorOnSmsSend: string = "";
    authRegNbrSubmitBtn: string = "";

    // ========================================================
    // Location Section
    // ========================================================

    // Location Selection Page
    locationSelectionInstructionRefinePinTxt: string = ""; // instruction
    locationSelectionDeliveryAddressTxt: string = "";
    locationSelectionSaveAddressTxtBtn: string = "";
    locationSelectionSelectAdressTxt: string = "";
    locationSelectionGpsDisabledOnDeviceTxtBnr: string = "";
    locationSelectionSearchTxtBtn: string = "";
    locationSelectionGpsDisabledDialogTitle: string = "";
    locationSelectionGpsDisabledDialogContent: string = "";
    locationSelectionGpsDisabledDialogCancel: string = "";
    locationSelectionGpsEnablAndroidDialogSubmit: string = "";  
    locationSelectionGpsEnableIosDialogSubmit: string = ""; 
    locationSelectionSearchHintTxtBtn: string = "";
    locationSelectionTryAgainTxt: string = "";
    gpsBtnText: string = "";
    submitLocationBtn: string = "";
    completeAddressIsRequired: string = "";
    tagDescriptionIsRequired: string = "";
    noAddressDetected: string = "";
    locationPermissionDeniedDialogTitle: string = "";
    locationPermissionDeniedDialogMsg: string = "";
    locationPermissionDeniedDialogBtn: string = "";

    // Address Details Bottom Sheet
    addressDetailsTitle: string = "";
    tagSectionTitle: string = "";
    tagDescriptionPlaceholder: string = "";
    otherAddressesTitle: string = "";
    listOfAddressesNotLoggedIn: string = "";
    addNewAddressBtn: string = "";
    deleteAddressDialogTitle: string = "";
    deleteAddressDialogTxt: string = "";
    addressMoreActionsEdit: string = "";
    addressMoreActionsDelete: string = "";
    setAddAsPrimary: string = "";
    addressMoreActionsSelect: string = "";


    // Store Agnostic Pickup Store Selection
    userLocMarkerTitle: string ="";
    selectAPickupStoreMsg: string ="";
  

    // ========================================================
    // Page Navigation Section
    // ========================================================

    // Marketplace Homepage
    helloPrefixMessage: string ="";
    helloMessageSubtitle: string ="";
    marketplaceSearchBarPlaceholder: string ="";
    listOfStoreTypesTitle: string ="";

    // Home Store Page
    storeHomePageBarTitle: string ="";
    nextDeliverySlot: string ="";
    nextPickupSlot: string ="";
    closesSoonOpeningStatus: string ="";
    openOpeningStatus: string ="";  
    closedOpeningStatus: string ="";
    atForOpeningHours: string ="";
    tillForOpeningHours: string ="";
    opensForOpeningHours: string ="";
    appBarTitleStoreHomePage: string ="";
    instantDeliveryBadge: string ="";
    instantDeliveryScheduleTitle: string ="";
    instantDeliveryScheduleSubtitle1: string ="";
    instantDeliveryScheduleSubtitle2: string ="";
    instantPickupScheduleTitle: string ="";

    // Main Navigation Page
    mainNavExitAppDialogTitle: string ="";
    mainNavExitAppDialogContent: string ="";
    mainNavExitAppDialogCancel: string ="";
    mainNavExitAppDialogSubmit: string ="";
    completedOrdersListBarTitle: string ="";

    // Store Selection Page
    storeSelectionNotCoveringAreaMsgSubtitleTxt: string =""; 
    storeSelectionDeliveryElligibleStoresTxtBnr: string ="";
    storeSelectionPreferedDeliveryStoreSelectionTxtBnr: string ="";
    storeSelectionPreferedPickUpStoreSelectionTxtBnr: string ="";
    storeSelectionMeterAwayTxt: string ="";
    storeSelectionKmAwayTxt: string ="";
    storeSelectionTooFarTxt: string ="";
    storeSelectionTitleSelectedStore: string ="";
    storeSelectionTitleOtherStores: string ="";
    storeSelectionTitleSelectAStore: string ="";
    storeSelectionPopupTitle: string ="";
    storeSelectionPopupDescription: string ="";
    storeSelectionOrdersMoreOptionsTxt: string ="";
    storeSelectionSettingsMoreOptionsTxt: string ="";
    noEligibleStoresEmptyViewTxt: string ="";
    noEligibleStoresEmptyViewBtn: string ="";
    viewAllOrdersBtn: string ="";
    guestAccountTitle: string ="";
    guestAccountSubTitle: string ="";
    loggedInAccountSubTitle: string ="";
    onlyPickupOrDeliveryText: string = "";

    // ========================================================
    // Products Related Section
    // ========================================================

    // Category Subcat Page
    catSubcatCnxPbTxtBnr: string ="";
    catSubcatSearchScopeBarTitle: string ="";
    catSubCatAppBarTitleForEditOrder: string ="";

    // Expandable list of items Page
    weightSensitivityTxtBnr: string ="";  // used in 2 pages : Product details page
    singleItemMaxQtyReachedTxtBnr: string =""; // used in 3 pages : Product details page & shopping cart page
    maxNbItemsReachedTxtBnr: string =""; // used in 3 pages : Product details page & shopping cart page
    maxTotalAmountReachedTxtBnr: string =""; // used in 3 pages : Product details page & shopping cart page
    promotionBadgeTxt: string =""; // used in 3 pages : Product details page & search page
    promotionOldPricePrefixTxt: string =""; // used in 3 pages : Product details page & search page
    promotionBadgePercentSuffix: string =""; // used in 3 pages : Product details page & search page
    promotionExpiryBadgeTxt: string ="";
    featuredBadgeTxt: string ="";
    singleItemMaxQtyReachedKnownItemPrefixTxtBanner: string ="";

    // Product Details Page
    productDetailsAppBarTitle: string ="";
    productDetailsInfoWeightVariationTxt: string ="";
    productDetailsSimilarFamilyProductsTitleTxt: string ="";
    productDetailsSimilarBrandProductsTitleTxt: string ="";
    productDetailsAlsoBoughtWithProductsTitleTxt: string ="";
    floatingBtnPrefix: string ="";
    addedToFavoriteItem: string ="";
    removedFromFavoriteItems: string ="";

    // Search Product Tab Page
    searchhProductTabBeginningOfPhraseBarTitle: string ="";
    searchProductTabNoResultsFoundTxt: string ="";
    searchProductTabTypeInSubcatTxt: string ="";
    searchProductTabYourRecentSearchesTxt: string ="";
    searchProductTabPopularCategories: string ="";

    // Items type at request time
    productTypeDescriptionByPromo: string ="";
    productTypeDescriptionByNewlyAdded: string ="";
    productTypeDescriptionByTopSelling: string ="";
    productTypeDescriptionByFeatureProducts: string ="";
    productTypeDescriptionBySubCat: string ="";
    productTypeDescriptionByBrand: string ="";
    productTypeDescriptionByCat: string ="";

    // List Of Items By Cat
    favoriteItemsAppBarTitle: string ="";
    fastShoppingItemsAppBarTitle: string ="";

    // ========================================================
    // Order Related Section
    // ========================================================

    // Alt OOSItems Page
    altOosItemsBarTitle: string ="";
    timerLessThanTxt: string =""; // less than // used in 2 pages: Order Summary page
    timerLessThanMinutesTxt: string =""; // less than X minutes // used in 2 pages: Order Summary page
    replacedWithTxt: string ="";  
    altOosItemsChangeReplacementTxtBtn: string ="";  
    altOosItemsMsgForTimeOutTxtBnr: string ="";  
    altOosItemsSubmitChangesTxtBtn: string ="";  
    altOosItemsStatusFlagSelectReplacementTxt: string =""; 
    altOosItemsStatusFlagForceDiscardedTxt: string ="";
    altOosItemsStatusFlagDiscardedFromStoreNoReplacementTxt: string ="";
    altOosItemsStatusFlagReplacementSelectedTxt: string ="";
    altOosItemsStatusFlagRemovedByUserTxt: string ="";
    altOosItemsInstructionsChoseReplacementsTxtBnr: string ="";
    disabledBtnSuffixTxtReplaced: string ="";
    disabledBtnSuffixTxtItemToBeReplaced: string ="";
    disabledBtnSuffixTxtItemsToBeReplaced: string ="";
    disabledBtnSuffixTxtRemoved: string ="";
    disabledBtnSuffixTxtToBeReplaced: string ="";
    replacementTimeoutIn5minsDialogTitle: string ="";
    replacementTimeoutIn5minsDialogContent: string ="";

    // Alternative Selection Per OOSItem Page
    altSelectionPerOosItemBarTitle: string ="";
    altSelectionPerOosItemChooseReplacementFromListTxt: string ="";
    altSelectionPerOosItemAltSameAsOssItemTxt: string ="";
    altSelectionPerOosItemNoneOfOptionListTitleTxt: string ="";
    altSelectionPerOosItemNoneOfOptionListSubtitleTxt: string ="";
    altSelectionQuantityChangeDialogTitle: string ="";
    altSelectionQuantityChangeDialogMaxQtyReachedMessageTxt: string ="";
    altSelectionQuantityChangeDialogBtnTxt: string ="";
    altSelectionQuantityChangeDialogCancelBtnTxt: string ="";
    
    // Checkout Page
    checkout1PersonalInfoBoxHeaderTxt: string ="";
    checkout1FullNameTitle: string =""; 
    checkout1AddressInfoBoxHeaderTxt: string ="";
    checkout1OrderTypeBoxHeaderTxt: string ="";
    checkout1SubmitTxtBtn: string ="";
    checkoutPaymentBoxHeaderTxt: string ="";
    checkoutScheduleBoxHeaderTxt: string ="";
    checkoutOrderSummaryBoxHeaderTxt: string ="";
    checkoutDeliveryLocationTitle: string ="";
    checkoutDeliveryCompleteAddressTitle: string ="";
    checkoutDeliveryCompleteAddressHintTxtField: string ="";
    checkout2LogisticsDetailsBoxHeaderTxt: string ="";
    checkout2SpecialRequestBoxHeaderTxt: string ="";
    checkout2SpecialRequestHintTxtField: string ="";
    orderSubtotalSubtitle: string =""; // used in 2 pages: OrderReceipt page
    orderSubtotalInclVATSubtitle: string =""; // used in 2 pages: OrderReceipt page
    discountSubtitle: string =""; // used in 2 pages: OrderReceipt page
    checkoutOrderSummaryVatSubtitle: string ="";
    deliveryChargesSubtitle: string ="";  // used in 2 pages: OrderReceipt page
    deliveryChargeFreeTxt: string ="";
    serviceFeeSubtitle: string ="";  // used in 2 pages: OrderReceipt page
    checkouSubmitTxtBtn: string ="";
    checkoutBarTitle: string ="";
    orderTotalSubtitle: string =""; // total //used in 3 pages :  Shopping cart, Order Receipt & Order Summary pages
    scheduleSlotBetweenTxt: string ="";
    scheduleSlotFirstAvailableTxt: string ="";
    scheduleSlotChangeBtn: string ="";
    orderTypeDeliverySubtitle: string ="";
    userNotEligibleDeliverySubtitle: string ="";
    orderTypePickupSubtitle1: string ="";
    orderTypePickupSubtitle2: string ="";
    placeOrderErrorTxtBnr: string ="";
    bottomSheetTitle: string ="";
    bottomSheetContactUsMsg: string ="";
    userNotEligiblePickupSubtitle1: string ="";
    userNotEligiblePickupSubtitle2: string ="";
    bottomSheetTitleValidatingPromo: string ="";
    bottomSheetTitleInvalidPromo: string ="";
    bottomSheetBtnSubmitAnyway: string ="";
    promoCodeTitle: string ="";
    promoCodePlaceHolder: string ="";  
    promoCodeBtn: string ="";  
    promoCodeInvalidBnrMsg: string ="";
    storeNotEligibleDeliverySubtitle: string ="";
    storeNotEligiblePickupSubtitle: string ="";
    checkoutSubmitChangesTxtBtn: string ="";
    bottomSheetScheduleSlotTxt: string ="";
    bottomSheetScheduleSlotBtnTxt: string ="";
    bottomSheetSubmitOrderSubtitle: string ="";
    bottomSheetPromoCodeOrderSubtitle: string ="";
    deliveryOptionStoreAgnosticSubtitle: string ="";
    includingDeliveryFees: string ="";
    specialRequestPlaceHolder: string ="";
    getStoreDirectionsTextBtn: string ="";
    checkoutNoCardSelectedForOnlinePaymentInformativeBannerMessage: string ="";
    checkoutReusableBagsSectionTitle: string ="";
    checkoutReusableBagsOptionTitle: string ="";
    checkoutReusableBagsOptionDescription: string ="";
    checkoutReusableBagsOptionKnowMoreBtn: string ="";
    checkoutPaymentCurrencyBoxHeader: string = "";
    checkoutPaymentCurrencyTerms: string = "";


    // Order Receipt Page
    orderReceiptDueToWeightChangeTxt: string ="";
    orderReceiptChangeDueToWeightAccuracyReceiptNotFinalTxt: string =""; // payment terms on order
    orderReceiptItemDiscardedTxt: string ="";
    orderReceiptItemReplacedWithTxt: string ="";
    orderReceiptVatTxt: string ="";
    orderReceiptBarTitle: string ="";
    orderReceiptTopInfoDialogTitle: string ="";

    // Order List Page
    orderListPagetitle: string =""; 
    orderListEmptyOrderListSubtitlePart1: string ="";
    orderListEmptyOrderListSubtitlePart2: string ="";  
    orderListAwaitingAltMessage: string ="";
    viewReceiptTxtBtn: string =""; // view receipt button // used in 2 pages: Order Summary Page
    listOfOrdersOrderFrom: string ="";

    // Order Summary Page
    personalInfoTitle: string =""; // used in 2 pages: checkout page OUI
    orderSummarySpecialRequestSubtitle: string =""; // subtitle instruction
    orderSummaryAddressSubtitle: string =""; // address instruction
    orderSummaryDeliveredTxt: string ="";
    orderSummaryPickedUpTxt: string ="";  
    orderSummaryCompletedTxt: string ="";
    orderSummaryCancelledTxt: string ="";
    orderSummaryEstimatedDeliveryTxt: string =""; // estimated time for delivery title
    orderSummaryEstimatedPickupTxt: string =""; // estimated time for pickup title
    orderSummaryOrderDelayedTxt: string ="";
    orderSummaryContactStoreTxtBtn: string ="";
    orderSummarySelectReplacementsTxtBtn: string ="";
    orderSummaryBarTitle: string ="";  
    orderSummaryOrderStatusItemsOosDiscardedFromOrderTxt: string =""; 
    orderSummaryRatingTitle: string =""; 
    orderSummaryRatingCommentPlaceholderTxt: string ="";
    orderSummaryBadRatingTxtBnr: string ="";
    orderSummaryGoodRatingTxtBnr: string ="";
    orderRatingDialogTitle: string ="";
    orderRatingDialogTxt: string =""; 
    orderRatingDialogRatingScoreError: string ="";
    orderRatingDialogBtnTxt: string ="";
    orderRatingDialogSkipBtnTxt: string ="";
    contactStoreMoreOptionsTxt: string ="";
    storeDirectionsMoreOptionsTxt: string ="";
    orderSummaryPromoCodePrefix: string ="";
    orderSummaryEditOrderOption: string ="";
    orderSummaryCancelOrderOption: string ="";
    orderSummaryMoreActions: string ="";
    bottomSheetEditOrderRequestTitle: string ="";
    bottomSheetEditOrderRequestSubTitle: string ="";
    bottomSheetCancelOrderRequestTitle: string ="";
    bottomSheetCancelOrderRequestSubTitle: string =""; 
    bottomSheetEditOrderDisabled: string ="";
    bottomSheetCancelOrderDisabled: string ="";
    orderSummaryCancelOrderDialogTitle: string ="";
    orderSummaryCancelOrderDialogTxt: string ="";
    deliveryOrderTypeStoreAgnosticSuffix: string ="";
    orderSummarySecretCodeTitleDelivery: string ="";
    orderSummarySecretCodeTitlePickup: string ="";

    // Schedule Page
    scheduleBarTitle1: string ="";
    scheduleBarTitle2: string ="";
    scheduleDoWTitle: string ="";
    scheduleTimeSlotTitle: string ="";
    scheduleBtnTxt: string ="";
    scheduleHighDemandTxt: string ="";
    scheduleUnavailableSlotTxtBnr: string ="";
    scheduleOriginalSlotBnrBtnKeepIt: string ="";
    scheduleOriginalSlotBnrBtnReset: string ="";
    scheduleOriginalSlotBnrPrefix: string ="";
    scheduleDayOfTheWeekToday: string ="";
    scheduleDayOfTheWeekTomorrow: string ="";
    editOrderScheduleOrderDisabledMsg: string ="";
    scheduleSlotBottomSheetOnSlotNull: string ="";

    // Shopping Cart Page
    shoppingCartProceedToCheckoutTxtBtn: string =""; // button checkout
    shoppingCartEmptyCartSubtitle: string =""; // empty cart subtitle 2
    shoppingCartDismissItemTxt: string =""; // dismiss item from cart  
    shoppingCartUndoDismissSnackBarLbl: string =""; 
    shoppingCartItemDismissedSnackBarTxt: string =""; 
    shoppingCartAndGetAFreeDeliveryTxt: string ="";
    shoppingCartCongratsTxt: string ="";
    shoppingCartInstructionDialogTitle: string ="";
    shoppingCartInstructionDialogPlaceHolder: string ="";
    shoppingCartPriceCheckMessage: string ="";
    shoppingCartPriceIncreased: string ="";
    shoppingCartPriceDecreased: string ="";
    shoppingCartAddInstruction: string ="";
    shoppingCartEditInstruction: string =""; 
    shoppingCartInstructionPrefix: string ="";
    shoppingCartAddItemBtn: string ="";
    shoppingCartDiscardChangesDialogtitle: string ="";
    shoppingCartDiscardChangesDialogMessage: string ="";
    shoppingCartMergeNewCartWithCurrentCartMsg: string ="";
    shoppingCartMergeNewCartWithCurrentCartMsgNo: string ="";
    shoppingCartAppBarTitleforEditOrder: string ="";
    shoppingCartAppBarTitle: string ="";
    bottomSheetDiscardChangesTitle: string ="";
    bottomSheetDiscardChangesSubTitle: string ="";
    editTimeMessagePart1: string ="";
    editTimeMessagePart2: string ="";
    cartsMergeExceedingNbItemsTotalPerOrder: string ="";
    cartsMergeExceedingItemQtyPerOrder: string ="";
    deliveryThresholdsBannerAddPrefix: string ="";
    deliveryThresholdBannerAddSuffix: string ="";
    deliveryThresholdNotReachedMsgPrefix: string ="";
    deliveryThresholdNotReachedMsgSuffix: string ="";
    deliveryThresholdErrorMessage: string ="";
    onlyExclItemsFormMOVPrefix: string ="";
    onlyExclItemsFormMOVSuffix: string ="";
    minOrderAmountExclItemsFormMOVPrefix: string ="";
    minOrderAmountExclItemsFormMOVMiddle: string ="";
    minOrderAmountExclItemsFormMOVSuffix: string ="";
    movRequiredPrefix: string ="";
    movRequiredSuffix: string ="";
    otherCartSectionTitle: string ="";
    currentCartSectionTitle: string ="";
    storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart1: string ="";
    storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart2: string ="";
    storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart3: string ="";
    storeAgnosticSingleStoreOtherCartMsg1AvlItemPart1: string ="";
    storeAgnosticSingleStoreOtherCartMsg1AvlItemPart2: string ="";
    storeAgnosticSingleStoreOtherCartMsgMoreThan1UnavlItemPart1: string ="";
    storeAgnosticSingleStoreOtherCartMsgMoreThan1UnavlItemPart2: string ="";
    storeAgnosticSingleStoreOtherCartMsg1UnavlItemPart1: string ="";
    storeAgnosticSingleStoreOtherCartMsg1UnavlItemPart2: string ="";
    multiStoreOtherCartMsgMoreThan1AvlItemPart1: string ="";
    multiStoreOtherCartMsgMoreThan1AvlItemPart2: string ="";
    multiStoreOtherCartMsgMoreThan1AvlItemPart3: string ="";
    multiStoreOtherCartMsg1AvlItemPart1: string ="";
    multiStoreOtherCartMsg1AvlItemPart2: string ="";
    multiStoreOtherCartMsgMoreThan1UnavlItemPart1: string ="";
    multiStoreOtherCartMsgMoreThan1UnavlItemPart2: string ="";
    multiStoreOtherCartMsg1UnavlItemPart1: string ="";
    multiStoreOtherCartMsg1UnavlItemPart2: string ="";
    otherCartAddBtn: string ="";

    // Edit order timeout dialog
    editOrderTimeOutDialogTitle: string ="";  
    editOrderTimeOutDialogSubTitle: string ="";  
    editOrderTimeOutDialogErrorMsg: string =""; 

    // Online Card Payment Page
    onlineCardPaymentCardNumberPrefix: string ="";
    onlineCardPaymentSelectedCardSectionTitle: string ="";
    onlineCardPaymentOtherCardsSectionTitle: string ="";
    onlineCardPaymentSelectCardSectionTitle: string ="";
    onlineCardPaymentAddNewCard: string ="";
    onlineCardPaymentRemoveCardDialogTitle: string ="";
    onlineCardPaymentRemoveCardDialogContentPrefix: string ="";
    onlineCardPaymentSaveChangesButton: string ="";
    onlineCardPaymentSetDefaultCardSectionTitle: string ="";
    onlineCardPaymentDefaultCardSectionTitle: string ="";
    onlineCardPaymentConfirmButton: string ="";
    onlineCardPaymentMoreOtionsTooltip: string ="";
    onlineCardPaymentMoreOtionsSelectCardOption: string ="";
    onlineCardPaymentMoreOtionsSetDefaultCardOption: string ="";
    onlineCardPaymentMoreOtionsRemoveCardOption: string ="";
    onlineCardPaymentExpiryText: string ="";
    onlineCardPaymentExpiredText: string ="";
    onlineCardPaymentExpiredErrorMessage: string ="";
    searchResultText: string ="";

    // ========================================================
    // Settings Section
    // ========================================================

    // Terms & Conditions Page
    termsConditionsBarTitle: string ="";

    // US Change Account Info Page
    usChangeAccountSaveChangesTxtBtn: string ="";
    usChangeAccountFirstNameBoxHeaderTxt: string ="";
    usChangeAccountLastNameBoxHeaderTxt: string ="";
    usChangeAccountPhoneNumberBoxHeaderTxt: string ="";
    usChangeAccountDateOfBirthBoxHeaderTxt: string ="";
    usChangeAccountGenderBoxHeaderTxt: string ="";
    usChangeAccountEmailBoxHeaderTxt: string ="";
    usChangeAccountChangesSubmittedflushBarMsg: string =""; 
    usChangeAccountNoChangesSubmittedflushBarMsg: string =""; 
    usChangeAccountTroubleSavingChangeslushBarMsg: string ="";
    usChangeAccountPersonalUserInfoBoxHeaderTxt: string =""; 
    usChangeAccountContactUserInfoBoxHeaderTxt: string =""; 
    usChangeAccountPhoneNumberFixedBoxHeaderTxt: string =""; 
    usChangeAccountInstructionSubmitBtnBoxHeaderTxt: string =""; 
    usChangeAccountSettingsInfoBarTxt: string ="";
    usChangeAccountEmailPlaceholder: string ="";
    dobPlaceHolder: string ="";
    emptyFirstNameAndOrLastName: string ="";
    usChangeAccountDeleteAccountTxtBtn: string ="";
    usChangeAccountDeleteAccountDialogTitle: string ="";
    usChangeAccountDeleteAccountDialogContent: string ="";
    usChangeAccountPasswordSectionTitle: string ="";
    usChangeAccountPasswordSectionContent: string ="";
    usChangeAccountChangePasswordTxtBtn: string ="";
    invalidEmail: string ="";

    // User Settings Page
    usSettingsContactUsUCTxtBtn: string ="";
    usSettingsDeliverySettingsBoxHeaderTxt: string =""; 
    usSettingsOrdersSettingsBoxHeaderTxt: string =""; 
    usSettingsMoreDetailsSettingsBoxHeaderTxt: string =""; 
    usSettingsLoyaltyProgramBoxTitle: string =""; 
    usSettingsLoyaltyStatusBoxContent: string ="";
    usSettingsPrimaryDeliveryAddressTxtBtn: string =""; 
    usSettingsSelectedStoreTxtBtn: string ="";
    usSettingsLogoutTxtBtn: string =""; 
    usSettingsContactUsTxtBtn: string =""; 
    usSettingsYourOrdersTxtBtn: string =""; 
    usSettingsPersonalInfoTxtBtn: string =""; 
    usSettingsAboutUsTxtBtn: string =""; 
    usSettingsTermsAndConditionsTxtBtn: string =""; 
    usSettingsChangingStoreByDefaultNotPossibleDialogContent: string ="";
    usSettingsChangingAddressDialogTitle: string ="";
    usSettingsChangingAddressDialogContent: string ="";
    usSettingsLogoutDialogTitle: string ="";
    usSettingsLogoutDialogContent: string ="";
    usSettingsUsIsNotLoggedCheckOrderDialogTitle: string ="";
    usSettingsUsIsNotLoggedCheckOrderDialogContent: string ="";
    usSettingsDialogCancel: string ="";
    usSettingsDialogSubmitOk: string ="";
    usSettingsDialogSubmitYes: string ="";
    usSettingsLoginOrRegisterTxtBtn: string ="";
    usSettingsChangeAccountInfoTxtBtn: string ="";
    usSettingsPartnerLoyaltyTitleTxtSuffix: string ="";
    usSettingsPartnerLoyaltyInvalidStateSubTitle: string ="";
    usSettingsPartnerLoyaltyAddBtn: string ="";
    usSettingsPartnerLoyaltyInvalidMessageTxt1: string ="";
    usSettingsPartnerLoyaltyInvalidMessageTxt2: string ="";
    usSettingsPartnerLoyaltyValidationStateSubTitle: string ="";
    usSettingsPartnerLoyaltyCreationStateSubTitle: string ="";
    usSettingsPartnerLoyaltyValidationMessageTxt1: string ="";
    usSettingsPartnerLoyaltyValidationMessageTxt2: string ="";
    usSettingsPartnerLoyaltyCreationMessageTxt: string ="";
    usSettingsPartnerLoyaltyLastUpdateOnPrefix: string ="";
    usSettingsPartnerLoyaltyCardNbPrefix: string ="";
    usSettingsPartnerLoyaltyRemoveBtn: string ="";
    usSettingsPartnerLoyaltyRemoveDialogTitle: string ="";
    usSettingsPartnerLoyaltyRemoveDialogSubTitle: string ="";
    usSettingsPartnerLoyaltyErrorOnDetachBnr: string ="";
    partnerLoyaltyCurrency: string ="";
    partnerLoyaltyUnit: string ="";
    privacyPolicy: string ="";
    usSettingsPaymentMethodTxtBtn: string ="";


    // Contact Us page
    contactUsAppBarTitle: string ="";
    contactUsSendMessageBtn: string ="";
    contactUsFullNameErrorMessage: string ="";
    contactUsPhoneNumberLengthErrorMessage: string ="";
    contactUsPhoneNumberContentErrorMessage: string ="";
    contactUsSubjectErrorMessage: string ="";
    contactUsMessageErrorMessage: string ="";
    contactUsCallTitlePart: string ="";
    contactUsFullnamePlaceholder: string ="";
    contactUsPhoneNumberPlaceholder: string ="";
    contactUsSubjectPlaceholder: string ="";
    contactUsMessagePlaceholder: string ="";
    contactUsMessageTitlePart: string ="";
    contactUsUsecasePhoneNbValidationPage: string ="";
    contactUsUsecasePhoneNbValidationPageForForgetPass: string ="";
    contactUsUsecaseLoginPage: string ="";
    contactUsUsecaseMainSettingsPage: string ="";
    contactUsUsecaseListOfStoreSettingsPage: string ="";
    contactUsUsecaseOrderSummaryPage: string ="";
    contactUsUsecasePlaceOrder: string ="";
    contactUsUsecaseEditOrderDisabled: string ="";
    contactUsUsecaseCancelOrderDisabled: string ="";
    contactUsUsecaseFromMarketplace: string ="";
    contactUsWhatsAppMsgPlaceOrderPart2: string ="";
    contactUsServiceReplyNotReceivedMessage: string ="";
    contactUsWhatsAppTitle: string ="";
    contactUsWhatsAppMsgPhoneNbValidation: string ="";
    contactUsWhatsAppMsgLogin: string ="";
    contactUsWhatsAppMsgUserLoggedIn1: string ="";
    contactUsWhatsAppMsgUserLoggedIn2: string ="";
    contactUsWhatsAppMsgUserNotLoggedIn: string ="";
    contactUsWhatsAppMsgOrderSummaryUserLoggedIn2: string ="";
    contactUsWhatsAppMsgKnownPhoneNbValidation: string ="";
    contactUsWhatsAppMsgLoginKnowPhoneNb: string ="";
    contactUsPreFilledSubjectForOrderSummary: string ="";
    contactUsPreFilledSubjectForNumberValidation: string ="";
    contactUsPreFilledSubjectForLogin: string ="";

    // Add partner Loyalty Card Page
    addPartnerLoyaltyAppBarTitle: string ="";
    cardNbHeaderTxt1: string ="";
    cardNbHeaderTxt2: string ="";
    cardNbPlaceHolderText: string ="";
    phoneNbHeaderTxt1: string ="";
    phoneNbHeaderTxt2: string ="";
    phoneNbPlaceHolderText: string ="";
    submitBtnText: string ="";
    errorCardNbText: string ="";
    errorPhoneNbText: string ="";
    requestPartnerLoyaltyTxt: string ="";
    requestPartnerLoyaltyBtn: string ="";
    errorOnServerResponseOnSubmit: string ="";
    errorOnServerResponseOnRequest: string ="";

    // Store Details Page
    openingHoursTitle: string ="";
    closedOpeningHours: string ="";
    paymentMethodsTitle: string ="";
    freedeliverymsgPrefix: string ="";
    storeDetailsGenericAppBarTitle: string ="";


    // ========================================================
    // UI Section
    // ========================================================

    //99 SubCat List Item
    subCatListItemsQuantityTxt: string =""; 

    // Add to Cart Button Page
    addToCartTxtBtn: string =""; // add to cart button content

    // Out of stock container (replacement of add to cart button when item is oos)
    outOfStockTxt: string =""; 

    // Custom App Bar Page
    changingStoreDialogTitle: string ="";     // Used in many pages when clicking to change the selected store Dialog // used in 2 pages: User Settings page
    changingStoreDialogContent: string =""; // Used in many pages when clicking to change the selected store Dialog // used in 2 pages: User Settings page
    unknownCityTxt: string =""; // used in 2 pages: custom banner location  
    unknownAddressTxt: string ="";   // used in 2 pages: custom banner location

    // Custom Banner Location Page
    changeLocationTxtBtn: string =""; // button change text


    // Custom Bottom Nav Bar Page
    customBottomNavStoreTabItemName: string ="";
    customBottomNavCategoriesTabItemName: string ="";
    customBottomNavOrdersTabItemName: string ="";
    customBottomNavMoreTabItemName: string ="";
    

    // Custom Prodcut Listing Page
    perKgtTxt: string =""; // used in 3 pages : Order Receipt & Product Detail page


    // Custom SubCat Card UI Page
    customSubCatCardUIIsTobaccoOrAlcohoolDialogSubmitTxt: string ="";
    isTobaccoDialogTitle: string ="";
    isTobaccoDialogContent: string ="";
    isAlcoholDialogTitle: string ="";
    isAlcoholDialogContent: string ="";


    // Custom UI Widget Page
    onGoingOrdersListBarTitle: string =""; // used in 2 pages : Main navigation page
    customUIWidegetDeliveryAdressCardDialogSubmitTxt: string ="";
    customUIWidegetDeliveryAdressCardDialogCancelTxt: string ="";
    customUIWidegetStartShoppinginCartEmptyViewTxtBtn: string ="";
    customUIWidegetStartShoppinginStoreEmptyViewTxtBtn: string ="";
    
    // Custom Web View Controller Page
    customWebViewControllerTermsAndConditionsBarTitle: string ="";

    // Custom Country Sheet
    countrySheetTitle: string ="";
    countrySheetSearchHint: string ="";
    
    // ========================================================
    // Model Section
    // ========================================================

    // Address
    addressErrorOnDetectionTxt: string =""; 
    addressUnamedRoadTxt: string =""; 
    homeTag: string ="";
    workTag: string ="";
    schoolTag: string ="";
    otherTag: string ="";


    // Orders Status
    cancelledOrder: string ="";
    scheduledOrder: string ="";
    newOrder: string ="";
    ongoingOrder: string ="";
    awaitingForReplacements: string ="";
    replacementsReceived: string ="";
    replacementsTimeOut: string ="";
    collectionCompleted: string ="";
    readyForDelivery: string ="";
    readyForPickup: string ="";
    inDeliveryOrder: string ="";
    delivered: string ="";
    pickupDone: string ="";
    completedOrder: string ="";
    cancelledOrderStatusInfo: string ="";
    newOrderStatusInfo: string ="";
    ongoingOrderStatusInfo: string ="";
    awaitingForReplStatusInfo: string ="";
    replacementsReceivedStatusInfo: string ="";
    replacementsTimeOutStatusInfo: string ="";
    collectionCompletedStatusInfo: string ="";
    readyForDeliveryOrderStatusInfo: string ="";
    readyForPickupOrderStatusInfo: string ="";
    inDeliveryOrderStatusInfo: string ="";
    deliveredStatusInfo: string ="";
    pickupDoneStatusInfo: string ="";
    completedOrderStatusInfo: string ="";
    allGrpStatus: string ="";
    scheduledGrpStatus: string ="";
    ongoingGrpStatus: string ="";
    completedGrpStatus: string ="";
    cancelledGrpStatus: string ="";
    
    // Order Types
    orderTypeUnknownTitle: string ="";
    orderTypeDeliveryTitle: string ="";
    orderTypePickupTitle: string ="";
    orderTypeInStoreTitle: string ="";
    orderTypeOtherTitle: string ="";

    // Partner Settings

    // Payment Method
    cashPayment: string ="";
    ccPayment: string ="";
    unknownPaymentMethod: string ="";
    cashPaymentOption: string ="";
    ccPaymentOption: string ="";
    payCashOnDeliveryTerms: string ="";
    payCCOnDeliveryTerms: string ="";
    payCashOnPickupTerms: string ="";
    payCCOnPickupTerms: string ="";
    onlineCCPayment: string ="";
    onlineCCPaymentOption: string ="";
    onlineCCTerms: string ="";
    mbOnDeliveryPayment: string ="";
    mbOnDeliveryPaymentOption: string ="";
    mbOnDeliveryTerms: string ="";
    mbOnPickupPayment: string ="";
    mbOnPickupPaymentOption: string ="";
    mbOnPickupTerms: string ="";
    OnlineCCPayment: string ="";

    // ========================================================
    // Server requests
    // ========================================================
    serverRequestError: string = "";


    // E-Wallet
    eWalletBalanceText: string = "";
    eWalletCashText: string = "";// LV934
    eWalletPaidByText: string = ""; //LV944  
    eWalletCheckoutBalanceText: string = ""; //LV935
    eWalletRemainingToBePaid: string = ""; //LV935
    eWalletNoTransactions: string = "";


    // Loyalty Program
    loyaltyProgramTitleText: string = "" //LV818
    usSettingsPartnerLoyaltyInitialStateSubTitle: string = "";
    usSettingsLoyaltyProgramDescriptionBoxContent: string = "";
    usSettingsLoyaltyProgramAddBtn: string = "";
    usSettingsLoyaltyProgramLastUpdatedOn: string = "";
    usSettingsLoyaltyProgramPoints: string = "";
    usSettingsLoyaltyProgramInvalidCard: string = "";
    usSettingsLoyaltyProgramAddLoyaltyCardNumberText: string = "";
    usSettingsLoyaltyProgramAddLoyaltyCardPhoneNumberText: string = "";
    usSettingsLoyaltyProgramAddLoyaltyPendingValidation: string = "";
    loyaltyCheckoutTitle: string = "";
    paidByLoyalty: string = "";
    
    registrationAppMigrationText: string = "";
    registrationNotConfirmedAccount: string = "";
    otherPaymentMethodsTitleText: string = "";
    orderETAText: string = "";
    orderIdText: string = "";
    promotedItemsPercentageText: string = "";
    cartMinimumOrderAmoutText: string = "";
    cartGetFreeDeliveryText: string = "";
    BCRSText: string = "";




static fetchDataContentObjectFromJson( json: any): AppDataContent { 
        var appDataContent: AppDataContent = {} as AppDataContent;

  // ========================================================
  // Utilities Section
  // ========================================================
  

  // App version upgrade Page
  appDataContent.obsoleteBuildHeadingContentTxt= json.LV1;
  appDataContent.obsoleteSubHeadingContentTxt= json.LV2;
  appDataContent.outdatedSubHeadingContentTxt= json.LV520;
  appDataContent.obsoleteBuildActionTxtBtn= json.LV3;

  // Maintenance App Page
  appDataContent.maintenanceHeadingContentTxt= json.LV4;
  appDataContent.maintenanceSubHeadingContentTxt= json.LV5.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.maintenanceActionTxtBtn= json.LV6;

  // Empty Subcat view Page
  appDataContent.emptySubcatHeadingContentTxt= json.LV7;
  appDataContent.emptySubcatSubHeadingContentTxt= json.LV8;
  appDataContent.emptySubcatActionTxtBtn= json.LV9;
  appDataContent.emptyPromotionSubHeading1= json.LV10;
  appDataContent.emptyPromotionSubHeading2= json.LV11;
  appDataContent.emptyFavoriteItemsHeadingContentTxt= json.LV559;
  appDataContent.emptyFastShoppingItemsHeadingContentTxt= json.LV560;
  appDataContent.emptyFavoriteItemsSubHeadingContentTxt= json.LV561;
  appDataContent.emptyFastShoppingItemsSubHeadingContentTxt= json.LV562;
 
  // Empty orders list not logged in
  appDataContent.orderListEmptyOrderListNotLoggedInTxt= json.LV12;
  appDataContent.fastShoppingEmptyViewNotLoggedInTxt= json.LV565;

  // Empty View Stripe Online Payment
  appDataContent.emptyViewOnlineCardPaymentSubheading= json.LV627;
  appDataContent.emptyViewStripeOnlinePaymentButton= json.LV628;

  appDataContent.emptySearchText = json.LV583;

  // ========================================================
  // Authentication Section
  // ========================================================

  // Welcome Page
  appDataContent.authWlcPartnerMsgTxt = json.LV13; 
  appDataContent.authWlcCreateNewAccountTxtBtn= json.LV14;
  appDataContent.authWlcLoginWithFacebookTxtBtn= json.LV15;
  appDataContent.authWlcLoginWithYourAccountTxtBtn= json.LV16;
  appDataContent.authWlcFeatureNotYetDoneTxtBnr= json.LV17;
  appDataContent.authWlcOrTxt= json.LV18;
  appDataContent.skipAppBarBtn= json.LV555;
  appDataContent.closeAppBarBtn= json.LV556;
  appDataContent.welcomeAuthLoginBtn= json.LV653;
  appDataContent.welcomeAuthRegisterBtn= json.LV654;
  appDataContent.welcomeAuthAgreementMsg1= json.LV655;
  appDataContent.welcomeAuthAgreementMsg2= json.LV656;

  // Register Name Page
  appDataContent.authRegNameBarTitle= json.LV19;
  appDataContent.authRegNamefirstnameTxtField= json.LV20;
  appDataContent.authRegNamelastnameTxtField= json.LV21;
  appDataContent.authRegNameGenderTxtField= json.LV657;
  appDataContent.authRegNameDateOfBirthTxtField= json.LV658;
  appDataContent.authRegNameFirstNameErrorMessage1= json.LV659;
  appDataContent.authRegNameFirstNameErrorMessage2= json.LV660;
  appDataContent.authRegNameLastNameErrorMessage1= json.LV661;
  appDataContent.authRegNameLastNameErrorMessage2= json.LV662;
  appDataContent.authRegNameNextBtn= json.LV663;

 
  // Register Age Gender Page
  appDataContent.authRegAgeGenderBarTitle= json.LV22;
  appDataContent.userGenderNoneStr= json.LV973;
  appDataContent.userGenderMaleStr= json.LV24;
  appDataContent.userGenderFemaleStr= json.LV25;
  appDataContent.authRegAgeGenderAgeTxtField= json.LV26;

  // Register Number Page
  appDataContent.authRegNbrBarTitle= json.LV27;
  appDataContent.nbrePwdTxtField= json.LV28; // used in 2 pages : Login page
  appDataContent.errorPwdLengthTxtField1 = json.LV29;  // used in 4 pages : Content.dart, Login & ForgotPwd page
  appDataContent.errorPwdLengthTxtField2 = json.LV30;  // used in 4 pages : Content.dart, Login & ForgotPwd page  
  appDataContent.errorOnSmsSend = json.LV31;
  appDataContent.authRegNbrSubmitBtn = json.LV664;

  // Register Validate Page
  appDataContent.authRegValidateExceededSmSRetriesTxt= json.LV32;
  appDataContent.authRegValidateEditPhoneNbSmsTxtBtn= json.LV33;
  appDataContent.authRegValidateResendSmsTxtBtn= json.LV34;
  appDataContent.authRegValidateCnxServerPbTxtBnr= json.LV35;
  appDataContent.authRegValidateTimerToResendSmsTxt= json.LV36;
  appDataContent.authRegValidatePswdsuccessfullyChangedTxt= json.LV37;
  appDataContent.authRegValidatePhoneCodeForgotPwdBarTitle= json.LV38;
  appDataContent.authRegValidateSmsSentSubTitleForgotPwdTxt= json.LV39;
  appDataContent.authRegValidatePhoneValidationBarTitle= json.LV40;
  appDataContent.authRegValidatephoneCodeValidationSubTitlePart1Txt= json.LV41; 
  appDataContent.authRegValidatephoneCodeValidationSubTitlePart2Txt= json.LV42; 
  appDataContent.authRegValidateInfoIssueMsgTxt = json.LV43; 
  appDataContent.authRegValidateErrorRenewalPwdSnackBar= json.LV44;
  appDataContent.authRegValidateIssueValidatingNewAccountSnackBar= json.LV45;
  appDataContent.authRegValidateCnxServerPbSnackBar= json.LV46;
  appDataContent.authRegValidateErrorIncorrectCodeSnackBar= json.LV47;
  appDataContent.authRegValidateErrorRceiverNullSnackBar= json.LV48;
  appDataContent.authRegValidateContactUsMessage= json.LV49;
 
  // Login Page
  appDataContent.authLoginFailureTxtBnr= json.LV50;
  appDataContent.authLoginBarTitle = json.LV51; 
  appDataContent.authLoginTxtBtn= json.LV52;
  appDataContent.authLoginForgotPwdTxtBtn= json.LV53;
  appDataContent.authLoginEmptyMobileNbreTxtBnr= json.LV54;
  appDataContent.authLoginEmptyPwdTxtBnr= json.LV55;
  appDataContent.authLoginContactUsMessage = json.LV56;
  appDataContent.loginPageLoginFailedDialogTitle = json.LV665;
  appDataContent.loginPageLoginFailedDialogMsg = json.LV666;
  appDataContent.loginPageLoginFailedDialogContactUsBtn = json.LV667;
  appDataContent.loginPageLoginFailedDialogCancelBtn = json.LV668;
  appDataContent.loginPageLoginAppBarTitle = json.LV669;

  // Forgot Password Mobile Page
  appDataContent.authForgotPwdBarTitle= json.LV57;
  appDataContent.authForgotPwdSubTitleMobileNbreUsedTxt= json.LV58;
  appDataContent.authForgotPwdSubTitleNewPwdTxt= json.LV59;
  appDataContent.authForgotPwdNewPwdTxtField= json.LV60;
  appDataContent.authForgotPwdEmptyMobileNbreTxtBnr= json.LV61;
  appDataContent.authForgotPwdMobileNbreLenghIncorrectTxtBnr= json.LV62;
  appDataContent.authForgotPwdLenghPwdIncorrecrTxtBnr= json.LV63;
  appDataContent.authForgotPwdCnxServerPbTxtBnr= json.LV64;
  appDataContent.authChangePwdBarTitle= json.LV652;
  appDataContent.authForgotPwdSubmitBtn= json.LV670;
 

  // ========================================================
  // Location Section
  // ========================================================

   // Location Selection Page
  appDataContent.locationSelectionInstructionRefinePinTxt= json.LV65; // instruction
  appDataContent.locationSelectionDeliveryAddressTxt= json.LV66;
  appDataContent.locationSelectionSaveAddressTxtBtn= json.LV67;
  appDataContent.locationSelectionSelectAdressTxt= json.LV68;
  appDataContent.locationSelectionGpsDisabledOnDeviceTxtBnr= json.LV69;
  appDataContent.locationSelectionSearchTxtBtn= json.LV70;
  appDataContent.locationSelectionGpsDisabledDialogTitle= json.LV71;
  appDataContent.locationSelectionGpsDisabledDialogContent = json.LV72;
  appDataContent.locationSelectionGpsDisabledDialogCancel= json.LV73;
  appDataContent.locationSelectionGpsEnablAndroidDialogSubmit= json.LV74;  
  appDataContent.locationSelectionGpsEnableIosDialogSubmit= json.LV75; 
  appDataContent.locationSelectionSearchHintTxtBtn= json.LV76;
  appDataContent.locationSelectionTryAgainTxt= json.LV77;
  appDataContent.gpsBtnText= json.LV513;
  appDataContent.submitLocationBtn= json.LV538;
  appDataContent.completeAddressIsRequired= json.LV574;
  appDataContent.tagDescriptionIsRequired= json.LV575;
  appDataContent.noAddressDetected= json.LV578.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.locationPermissionDeniedDialogTitle= json.LV579;
  appDataContent.locationPermissionDeniedDialogMsg= json.LV580;
  appDataContent.locationPermissionDeniedDialogBtn= json.LV581;

  // Address Details Bottom Sheet
  appDataContent.addressDetailsTitle= json.LV539;
  appDataContent.tagSectionTitle= json.LV540;
  appDataContent.tagDescriptionPlaceholder= json.LV541;
  appDataContent.otherAddressesTitle= json.LV542;
  appDataContent.listOfAddressesNotLoggedIn= json.LV543;
  appDataContent.addNewAddressBtn= json.LV544;
  appDataContent.deleteAddressDialogTitle= json.LV545;
  appDataContent.deleteAddressDialogTxt= json.LV546;
  appDataContent.addressMoreActionsEdit= json.LV547;
  appDataContent.addressMoreActionsDelete= json.LV548;
  appDataContent.setAddAsPrimary= json.LV608;
  appDataContent.addressMoreActionsSelect= json.LV609;

  // Welcome Page
  appDataContent.welcomeMsgTxt= json.LV78; // Hi USER, ...
  appDataContent.welcomeGuidanceMsgTxt= json.LV79; // Set location to explore...
  appDataContent.welcomeDeliveryLocationTxtBtn= json.LV80; //Get location via GPS
  appDataContent.welcomeLocationTermsMsgTxt= json.LV81; // Terms of usage of your location data
  appDataContent.currentLocationButton= json.LV576;
  appDataContent.manuallyLocationButton= json.LV577;
  appDataContent.googlePlacesSearchGuidanceEmptyView= json.LV582;
  appDataContent.googlePlacesSearchNoResultEmptyView= json.LV583;
  appDataContent.welcomeAuthPartnerSpecificIntoMsg=  typeof json.LVLV != "undefined" && json.LVLV != null && (json.LVLV).toString().length > 0 ? json.LVLV : json.LV827;

  // Store Agnostic Pickup Store Selection
  appDataContent.userLocMarkerTitle= json.LV536;
  appDataContent.selectAPickupStoreMsg= json.LV537;

  // ========================================================
  // Page Navigation Section
  // ========================================================

  // Marketplace Homepage
  appDataContent.helloPrefixMessage= json.LV611;
  appDataContent.helloMessageSubtitle= json.LV612;
  appDataContent.marketplaceSearchBarPlaceholder= json.LV613;
  appDataContent.listOfStoreTypesTitle= json.LV617;

  // Store HomePage
  appDataContent.storeHomePageBarTitle= json.LV82;
  appDataContent.nextDeliverySlot= json.LV495;
  appDataContent.nextPickupSlot= json.LV496;
  appDataContent.closesSoonOpeningStatus= json.LV497;
  appDataContent.openOpeningStatus= json.LV498; 
  appDataContent.closedOpeningStatus= json.LV499;
  appDataContent.atForOpeningHours= json.LV500;
  appDataContent.tillForOpeningHours= json.LV501;
  appDataContent.opensForOpeningHours= json.LV502;
  appDataContent.appBarTitleStoreHomePage= json.LV503;
  appDataContent.instantDeliveryBadge= json.LV528;
  appDataContent.instantDeliveryScheduleTitle= json.LV529;
  appDataContent.instantDeliveryScheduleSubtitle1= json.LV530;
  appDataContent.instantDeliveryScheduleSubtitle2= json.LV531;
  appDataContent.instantPickupScheduleTitle= json.LV610;


  // Main Navigation Page
  appDataContent.mainNavExitAppDialogTitle= json.LV83;
  appDataContent.mainNavExitAppDialogContent = json.LV84;
  appDataContent.mainNavExitAppDialogCancel= json.LV85;
  appDataContent.mainNavExitAppDialogSubmit= json.LV86;
  appDataContent.completedOrdersListBarTitle= json.LV87;

  // Store Selection Page
  appDataContent.storeSelectionNotCoveringAreaMsgSubtitleTxt= json.LV103; 
  appDataContent.storeSelectionDeliveryElligibleStoresTxtBnr= json.LV88;
  appDataContent.storeSelectionPreferedDeliveryStoreSelectionTxtBnr= json.LV104;
  appDataContent.storeSelectionPreferedPickUpStoreSelectionTxtBnr= json.LV105;
  appDataContent.storeSelectionMeterAwayTxt= json.LV89;
  appDataContent.storeSelectionKmAwayTxt= json.LV90;
  appDataContent.storeSelectionTooFarTxt= json.LV91;
  appDataContent.storeSelectionTitleSelectedStore= json.LV92;
  appDataContent.storeSelectionTitleOtherStores= json.LV93;
  appDataContent.storeSelectionTitleSelectAStore= json.LV94;
  appDataContent.storeSelectionPopupTitle= json.LV95;
  appDataContent.storeSelectionPopupDescription= json.LV96;
  appDataContent.storeSelectionOrdersMoreOptionsTxt= json.LV106;
  appDataContent.storeSelectionSettingsMoreOptionsTxt= json.LV107;
  appDataContent.noEligibleStoresEmptyViewTxt= json.LV97.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.noEligibleStoresEmptyViewBtn= json.LV98;
  appDataContent.viewAllOrdersBtn= json.LV99;
  appDataContent.guestAccountTitle= json.LV100;
  appDataContent.guestAccountSubTitle= json.LV101;
  appDataContent.loggedInAccountSubTitle= json.LV102;
  appDataContent.onlyPickupOrDeliveryText = json.LV691;

  // ========================================================
  // Products Related Section
  // ========================================================

  // Category Subcat Page
  appDataContent.catSubcatCnxPbTxtBnr= json.LV108;
  appDataContent.catSubcatSearchScopeBarTitle= json.LV109;
  appDataContent.catSubCatAppBarTitleForEditOrder= json.LV452;

  // Expandable list of items Page
  appDataContent.weightSensitivityTxtBnr= json.LV111;  // used in 2 pages : Product details page
  appDataContent.singleItemMaxQtyReachedTxtBnr= json.LV112; // used in 3 pages : Product details page & shopping cart page
  appDataContent.maxNbItemsReachedTxtBnr= json.LV113; // used in 3 pages : Product details page & shopping cart page
  appDataContent.maxTotalAmountReachedTxtBnr= json.LV114; // used in 3 pages : Product details page & shopping cart page
  appDataContent.promotionBadgeTxt= json.LV115; // used in 3 pages : Product details page & search page
  appDataContent.promotionOldPricePrefixTxt= json.LV116; // used in 3 pages : Product details page & search page
  appDataContent.promotionBadgePercentSuffix = json.LV117; // used in 3 pages : Product details page & search page
  appDataContent.promotionExpiryBadgeTxt = json.LV475; 
  appDataContent.featuredBadgeTxt = json.LV491; 
  appDataContent.singleItemMaxQtyReachedKnownItemPrefixTxtBanner = json.LV521;

  // Product Details Page
  appDataContent.productDetailsAppBarTitle= json.LV118;
  appDataContent.productDetailsInfoWeightVariationTxt= json.LV119;
  appDataContent.productDetailsSimilarFamilyProductsTitleTxt= json.LV120;
  appDataContent.productDetailsSimilarBrandProductsTitleTxt= json.LV121;
  appDataContent.productDetailsAlsoBoughtWithProductsTitleTxt= json.LV122;
  appDataContent.floatingBtnPrefix= json.LV492;
  appDataContent.addedToFavoriteItem= json.LV584;
  appDataContent.removedFromFavoriteItems= json.LV585;

  // Search Product Tab Page
  appDataContent.searchhProductTabBeginningOfPhraseBarTitle= json.LV123;
  appDataContent.searchProductTabNoResultsFoundTxt= json.LV124;
  appDataContent.searchProductTabTypeInSubcatTxt= json.LV125;
  appDataContent.searchProductTabYourRecentSearchesTxt= json.LV126;
  appDataContent.searchProductTabPopularCategories= json.LV483;

  // Items type at request time
  appDataContent.productTypeDescriptionByPromo= json.LV127;
  appDataContent.productTypeDescriptionByNewlyAdded= json.LV484;
  appDataContent.productTypeDescriptionByTopSelling= json.LV485;
  appDataContent.productTypeDescriptionByFeatureProducts= json.LV486;
  appDataContent.productTypeDescriptionBySubCat= json.LV487;
  appDataContent.productTypeDescriptionByBrand= json.LV488;
  appDataContent.productTypeDescriptionByCat= json.LV527;

  // List Of Items By Cat
  appDataContent.favoriteItemsAppBarTitle= json.LV563;
  appDataContent.fastShoppingItemsAppBarTitle= json.LV564;

  // ========================================================
  // Order Related Section
  // ========================================================

  // Alt OOSItems Page
  appDataContent.altOosItemsBarTitle= json.LV128;
  appDataContent.timerLessThanTxt= json.LV129; // less than // used in 2 pages: Order Summary page
  appDataContent.timerLessThanMinutesTxt= json.LV130; // less than X minutes // used in 2 pages: Order Summary page
  appDataContent.replacedWithTxt= json.LV131;  
  appDataContent.altOosItemsChangeReplacementTxtBtn= json.LV132;
  appDataContent.altOosItemsMsgForTimeOutTxtBnr= json.LV133;  
  appDataContent.altOosItemsSubmitChangesTxtBtn= json.LV134;  
  appDataContent.altOosItemsStatusFlagSelectReplacementTxt= json.LV135; 
  appDataContent.altOosItemsStatusFlagForceDiscardedTxt= json.LV136;
  appDataContent.altOosItemsStatusFlagDiscardedFromStoreNoReplacementTxt= json.LV137;
  appDataContent.altOosItemsStatusFlagReplacementSelectedTxt= json.LV138;
  appDataContent.altOosItemsStatusFlagRemovedByUserTxt= json.LV139;
  appDataContent.altOosItemsInstructionsChoseReplacementsTxtBnr= json.LV140;
  appDataContent.disabledBtnSuffixTxtReplaced= json.LV505;
  appDataContent.disabledBtnSuffixTxtItemToBeReplaced= json.LV526;
  appDataContent.disabledBtnSuffixTxtItemsToBeReplaced= json.LV506;
  appDataContent.disabledBtnSuffixTxtRemoved= json.LV507;
  appDataContent.disabledBtnSuffixTxtToBeReplaced= json.LV508;
  appDataContent.replacementTimeoutIn5minsDialogTitle= json.LV522;
  appDataContent.replacementTimeoutIn5minsDialogContent= json.LV523;

  // Alternative Selection Per OOSItem Page
  appDataContent.altSelectionPerOosItemBarTitle= json.LV141;
  appDataContent.altSelectionPerOosItemChooseReplacementFromListTxt= json.LV142;
  appDataContent.altSelectionPerOosItemAltSameAsOssItemTxt= json.LV143;
  appDataContent.altSelectionPerOosItemNoneOfOptionListTitleTxt= json.LV144;
  appDataContent.altSelectionPerOosItemNoneOfOptionListSubtitleTxt= json.LV145;
  appDataContent.altSelectionQuantityChangeDialogTitle= json.LV146;
  appDataContent.altSelectionQuantityChangeDialogMaxQtyReachedMessageTxt= json.LV147;
  appDataContent.altSelectionQuantityChangeDialogBtnTxt= json.LV148;
  appDataContent.altSelectionQuantityChangeDialogCancelBtnTxt= json.LV149;
 
  // Checkout Page
  appDataContent.checkout1PersonalInfoBoxHeaderTxt= json.LV150;
  appDataContent.checkout1FullNameTitle= json.LV151; 
  appDataContent.checkout1AddressInfoBoxHeaderTxt= json.LV152;
  appDataContent.checkout1OrderTypeBoxHeaderTxt= json.LV153;
  appDataContent.checkout1SubmitTxtBtn= json.LV154;
  appDataContent.checkoutPaymentBoxHeaderTxt= json.LV155;
  appDataContent.checkoutScheduleBoxHeaderTxt= json.LV156;
  appDataContent.checkoutOrderSummaryBoxHeaderTxt= json.LV157;
  appDataContent.checkoutDeliveryLocationTitle= json.LV158;
  appDataContent.checkoutDeliveryCompleteAddressTitle= json.LV159;
  appDataContent.checkoutDeliveryCompleteAddressHintTxtField= json.LV160;
  appDataContent.checkout2LogisticsDetailsBoxHeaderTxt= json.LV161;
  appDataContent.checkout2SpecialRequestBoxHeaderTxt= json.LV162;
  appDataContent.checkout2SpecialRequestHintTxtField= json.LV163;
  appDataContent.orderSubtotalSubtitle= json.LV164; // used in 2 pages: OrderReceipt page
  appDataContent.orderSubtotalInclVATSubtitle= json.LV165; // used in 2 pages: OrderReceipt page
  appDataContent.discountSubtitle= json.LV166; // used in 2 pages: OrderReceipt page
  appDataContent.checkoutOrderSummaryVatSubtitle= json.LV167;
  appDataContent.deliveryChargesSubtitle= json.LV168;  // used in 2 pages: OrderReceipt page
  appDataContent.deliveryChargeFreeTxt= json.LV169;
  appDataContent.serviceFeeSubtitle= json.LV170;  // used in 2 pages: OrderReceipt page
  appDataContent.checkouSubmitTxtBtn= json.LV171;
  appDataContent.checkoutBarTitle= json.LV172;
  appDataContent.orderTotalSubtitle= json.LV173; // total //used in 3 pages :  Shopping cart, Order Receipt & Order Summary pages
  appDataContent.scheduleSlotBetweenTxt= json.LV174;
  appDataContent.scheduleSlotFirstAvailableTxt= json.LV175;
  appDataContent.scheduleSlotChangeBtn= json.LV176;
  appDataContent.orderTypeDeliverySubtitle= json.LV177;
  appDataContent.userNotEligibleDeliverySubtitle= json.LV178;
  appDataContent.orderTypePickupSubtitle1= json.LV181;
  appDataContent.orderTypePickupSubtitle2= json.LV182;
  appDataContent.placeOrderErrorTxtBnr= json.LV183;
  appDataContent.bottomSheetTitle= json.LV184;
  appDataContent.bottomSheetContactUsMsg= json.LV185;
  appDataContent.userNotEligiblePickupSubtitle1= json.LV179;
  appDataContent.userNotEligiblePickupSubtitle2= json.LV180;
  appDataContent.bottomSheetTitleValidatingPromo= json.LV186;
  appDataContent.bottomSheetTitleInvalidPromo= json.LV187;
  appDataContent.bottomSheetBtnSubmitAnyway= json.LV188;
  appDataContent.promoCodeTitle= json.LV189;
  appDataContent.promoCodePlaceHolder= json.LV190;  
  appDataContent.promoCodeBtn= json.LV191;  
  appDataContent.promoCodeInvalidBnrMsg= json.LV192;
  appDataContent.storeNotEligibleDeliverySubtitle= json.LV435;
  appDataContent.storeNotEligiblePickupSubtitle= json.LV436;
  appDataContent.checkoutSubmitChangesTxtBtn= json.LV437;
  appDataContent.bottomSheetScheduleSlotTxt= json.LV445;
  appDataContent.bottomSheetScheduleSlotBtnTxt= json.LV446;
  appDataContent.bottomSheetSubmitOrderSubtitle= json.LV476;
  appDataContent.bottomSheetPromoCodeOrderSubtitle= json.LV477;
  appDataContent.deliveryOptionStoreAgnosticSubtitle= json.LV533;
  appDataContent.includingDeliveryFees= json.LV553;
  appDataContent.specialRequestPlaceHolder= json.LV554;
  appDataContent.getStoreDirectionsTextBtn= json.LV616;
  appDataContent.checkoutNoCardSelectedForOnlinePaymentInformativeBannerMessage= json.LV629;
  appDataContent.checkoutReusableBagsSectionTitle= json.LV679;
  appDataContent.checkoutReusableBagsOptionTitle= json.LV680;
  appDataContent.checkoutReusableBagsOptionDescription= json.LV681.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n";
  appDataContent.checkoutReusableBagsOptionKnowMoreBtn= json.LV682;
  appDataContent.checkoutPaymentCurrencyBoxHeader= json.LV850;
  appDataContent.checkoutPaymentCurrencyTerms= json.LV851;


  // Order Receipt Page
  appDataContent.orderReceiptDueToWeightChangeTxt= json.LV193;
  appDataContent.orderReceiptChangeDueToWeightAccuracyReceiptNotFinalTxt= json.LV194; // payment terms on order
  appDataContent.orderReceiptItemDiscardedTxt= json.LV195;
  appDataContent.orderReceiptItemReplacedWithTxt= json.LV196;
  appDataContent.orderReceiptVatTxt= json.LV197;
  appDataContent.orderReceiptBarTitle= json.LV198;
  appDataContent.orderReceiptTopInfoDialogTitle= json.LV199;

  // Order List Page
  appDataContent.orderListPagetitle= json.LV200; 
  appDataContent.orderListEmptyOrderListSubtitlePart1= json.LV201;
  appDataContent.orderListEmptyOrderListSubtitlePart2= json.LV202;  
  appDataContent.orderListAwaitingAltMessage= json.LV203;
  appDataContent.viewReceiptTxtBtn= json.LV204; // view receipt button // used in 2 pages: Order Summary Page
  appDataContent.listOfOrdersOrderFrom= json.LV535;

  // Order Summary Page
  appDataContent.personalInfoTitle= json.LV205; // used in 2 pages: checkout page OUI
  appDataContent.orderSummarySpecialRequestSubtitle= json.LV206; // subtitle instruction
  appDataContent.orderSummaryAddressSubtitle= json.LV207; // address instruction
  appDataContent.orderSummaryDeliveredTxt= json.LV208;
  appDataContent.orderSummaryPickedUpTxt= json.LV209;  
  appDataContent.orderSummaryCompletedTxt= json.LV210;
  appDataContent.orderSummaryCancelledTxt= json.LV211;
  appDataContent.orderSummaryEstimatedDeliveryTxt= json.LV212; // estimated time for delivery title
  appDataContent.orderSummaryEstimatedPickupTxt= json.LV213; // estimated time for pickup title
  appDataContent.orderSummaryOrderDelayedTxt= json.LV214;
  appDataContent.orderSummaryContactStoreTxtBtn= json.LV215;
  appDataContent.orderSummarySelectReplacementsTxtBtn= json.LV216;
  appDataContent.orderSummaryBarTitle= json.LV217;  
  appDataContent.orderSummaryOrderStatusItemsOosDiscardedFromOrderTxt= json.LV218; 
  appDataContent.orderSummaryRatingTitle= json.LV219; 
  appDataContent.orderSummaryRatingCommentPlaceholderTxt= json.LV220;
  appDataContent.orderSummaryBadRatingTxtBnr =  json.LV221;
  appDataContent.orderSummaryGoodRatingTxtBnr =  json.LV222;
  appDataContent.orderRatingDialogTitle= json.LV223;
  appDataContent.orderRatingDialogTxt= json.LV224;
  appDataContent.orderRatingDialogRatingScoreError = json.LV225;
  appDataContent.orderRatingDialogBtnTxt= json.LV226;
  appDataContent.orderRatingDialogSkipBtnTxt= json.LV227;
  appDataContent.contactStoreMoreOptionsTxt= json.LV228;
  appDataContent.storeDirectionsMoreOptionsTxt= json.LV229;
  appDataContent.orderSummaryPromoCodePrefix= json.LV230;
  appDataContent.orderSummaryEditOrderOption= json.LV438;
  appDataContent.orderSummaryCancelOrderOption= json.LV461;
  appDataContent.orderSummaryMoreActions= json.LV439;
  appDataContent.bottomSheetEditOrderRequestTitle= json.LV453;
  appDataContent.bottomSheetEditOrderRequestSubTitle= json.LV454;
  appDataContent.bottomSheetCancelOrderRequestTitle= json.LV462;
  appDataContent.bottomSheetCancelOrderRequestSubTitle= json.LV463;  
  appDataContent.bottomSheetEditOrderDisabled= json.LV466;  
  appDataContent.bottomSheetCancelOrderDisabled= json.LV467;  
  appDataContent.orderSummaryCancelOrderDialogTitle= json.LV468;  
  appDataContent.orderSummaryCancelOrderDialogTxt= json.LV469;  
  appDataContent.deliveryOrderTypeStoreAgnosticSuffix= json.LV534;
  appDataContent.orderSummarySecretCodeTitleDelivery= json.LV614;
  appDataContent.orderSummarySecretCodeTitlePickup= json.LV615;



  // Schedule Page
  appDataContent.scheduleBarTitle1= json.LV231;
  appDataContent.scheduleBarTitle2= json.LV232;
  appDataContent.scheduleDoWTitle= json.LV233;
  appDataContent.scheduleTimeSlotTitle= json.LV234;
  appDataContent.scheduleBtnTxt= json.LV235;
  appDataContent.scheduleHighDemandTxt= json.LV236;
  appDataContent.scheduleUnavailableSlotTxtBnr= json.LV457;
  appDataContent.scheduleOriginalSlotBnrBtnKeepIt = json.LV458;
  appDataContent.scheduleOriginalSlotBnrBtnReset = json.LV479;
  appDataContent.scheduleOriginalSlotBnrPrefix = json.LV478;
  appDataContent.scheduleDayOfTheWeekToday = json.LV489;
  appDataContent.scheduleDayOfTheWeekTomorrow = json.LV490;
  appDataContent.editOrderScheduleOrderDisabledMsg = json.LV557;
  appDataContent.scheduleSlotBottomSheetOnSlotNull = json.LV558;

  // Shopping Cart Page
  appDataContent.shoppingCartProceedToCheckoutTxtBtn= json.LV237; // button checkout
  appDataContent.shoppingCartEmptyCartSubtitle= json.LV238; // empty cart subtitle 2
  appDataContent.shoppingCartDismissItemTxt= json.LV239; // dismiss item from cart  
  appDataContent.shoppingCartUndoDismissSnackBarLbl= json.LV240; 
  appDataContent.shoppingCartItemDismissedSnackBarTxt= json.LV241; 
  appDataContent.shoppingCartAndGetAFreeDeliveryTxt= json.LV242;
  appDataContent.shoppingCartCongratsTxt= json.LV243;
  appDataContent.shoppingCartInstructionDialogTitle= json.LV244;
  appDataContent.shoppingCartInstructionDialogPlaceHolder= json.LV245;
  appDataContent.shoppingCartPriceCheckMessage= json.LV246;
  appDataContent.shoppingCartPriceIncreased= json.LV247;
  appDataContent.shoppingCartPriceDecreased= json.LV248;
  appDataContent.shoppingCartAddInstruction= json.LV249;
  appDataContent.shoppingCartEditInstruction= json.LV250; 
  appDataContent.shoppingCartInstructionPrefix= json.LV251;
  appDataContent.shoppingCartAddItemBtn= json.LV440;
  appDataContent.shoppingCartDiscardChangesDialogtitle= json.LV441;
  appDataContent.shoppingCartDiscardChangesDialogMessage= json.LV442;
  appDataContent.shoppingCartMergeNewCartWithCurrentCartMsg= json.LV443;
  appDataContent.shoppingCartMergeNewCartWithCurrentCartMsgNo= json.LV444;
  appDataContent.shoppingCartAppBarTitleforEditOrder= json.LV455;
  appDataContent.shoppingCartAppBarTitle= json.LV456;
  appDataContent.bottomSheetDiscardChangesTitle= json.LV471;
  appDataContent.bottomSheetDiscardChangesSubTitle= json.LV472;
  appDataContent.editTimeMessagePart1= json.LV473;
  appDataContent.editTimeMessagePart2= json.LV474;
  appDataContent.cartsMergeExceedingNbItemsTotalPerOrder= json.LV509.replaceAll("\\n", "\n");
  appDataContent.cartsMergeExceedingItemQtyPerOrder= json.LV510;
  appDataContent.deliveryThresholdsBannerAddPrefix= json.LV514;
  appDataContent.deliveryThresholdBannerAddSuffix= json.LV515;
  appDataContent.deliveryThresholdNotReachedMsgPrefix= json.LV516;
  appDataContent.deliveryThresholdNotReachedMsgSuffix= json.LV517;
  appDataContent.deliveryThresholdErrorMessage= json.LV518;
  appDataContent.onlyExclItemsFormMOVPrefix= json.LV567;
  appDataContent.onlyExclItemsFormMOVSuffix= json.LV568;
  appDataContent.minOrderAmountExclItemsFormMOVPrefix= json.LV569;
  appDataContent.minOrderAmountExclItemsFormMOVMiddle= json.LV570;
  appDataContent.minOrderAmountExclItemsFormMOVSuffix= json.LV571;
  appDataContent.movRequiredPrefix= json.LV572;
  appDataContent.movRequiredSuffix= json.LV573;
  appDataContent.otherCartSectionTitle= json.LV587;
  appDataContent.currentCartSectionTitle= json.LV588;
  appDataContent.storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart1= json.LV589;
  appDataContent.storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart2= json.LV590;
  appDataContent.storeAgnosticSingleStoreOtherCartMsgMoreThan1AvlItemPart3= json.LV591;
  appDataContent.storeAgnosticSingleStoreOtherCartMsg1AvlItemPart1= json.LV592;
  appDataContent.storeAgnosticSingleStoreOtherCartMsg1AvlItemPart2= json.LV593;
  appDataContent.storeAgnosticSingleStoreOtherCartMsgMoreThan1UnavlItemPart1= json.LV594;
  appDataContent.storeAgnosticSingleStoreOtherCartMsgMoreThan1UnavlItemPart2= json.LV595;
  appDataContent.storeAgnosticSingleStoreOtherCartMsg1UnavlItemPart1= json.LV596;
  appDataContent.storeAgnosticSingleStoreOtherCartMsg1UnavlItemPart2= json.LV597;
  appDataContent.multiStoreOtherCartMsgMoreThan1AvlItemPart1= json.LV598;
  appDataContent.multiStoreOtherCartMsgMoreThan1AvlItemPart2= json.LV599;
  appDataContent.multiStoreOtherCartMsgMoreThan1AvlItemPart3= json.LV600;
  appDataContent.multiStoreOtherCartMsg1AvlItemPart1= json.LV601;
  appDataContent.multiStoreOtherCartMsg1AvlItemPart2= json.LV602;
  appDataContent.multiStoreOtherCartMsgMoreThan1UnavlItemPart1= json.LV603;
  appDataContent.multiStoreOtherCartMsgMoreThan1UnavlItemPart2= json.LV604;
  appDataContent.multiStoreOtherCartMsg1UnavlItemPart1= json.LV605;
  appDataContent.multiStoreOtherCartMsg1UnavlItemPart2= json.LV606;
  appDataContent.otherCartAddBtn= json.LV607;

  // Edit order timeout dialog
  appDataContent.editOrderTimeOutDialogTitle= json.LV480;  
  appDataContent.editOrderTimeOutDialogSubTitle= json.LV481;  
  appDataContent.editOrderTimeOutDialogErrorMsg= json.LV482;  

  // Online Card Payment Page
  appDataContent.onlineCardPaymentCardNumberPrefix= json.LV630; 
  appDataContent.onlineCardPaymentSelectedCardSectionTitle= json.LV631; 
  appDataContent.onlineCardPaymentOtherCardsSectionTitle= json.LV632; 
  appDataContent.onlineCardPaymentSelectCardSectionTitle= json.LV633; 
  appDataContent.onlineCardPaymentAddNewCard= json.LV634; 
  appDataContent.onlineCardPaymentRemoveCardDialogTitle= json.LV635; 
  appDataContent.onlineCardPaymentRemoveCardDialogContentPrefix= json.LV636; 
  appDataContent.onlineCardPaymentSaveChangesButton= json.LV637; 
  appDataContent.onlineCardPaymentSetDefaultCardSectionTitle= json.LV639; 
  appDataContent.onlineCardPaymentDefaultCardSectionTitle= json.LV640; 
  appDataContent.onlineCardPaymentConfirmButton= json.LV641; 
  appDataContent.onlineCardPaymentMoreOtionsTooltip= json.LV642; 
  appDataContent.onlineCardPaymentMoreOtionsSelectCardOption= json.LV643;
  appDataContent.onlineCardPaymentMoreOtionsSetDefaultCardOption= json.LV644;
  appDataContent.onlineCardPaymentMoreOtionsRemoveCardOption= json.LV645;
  appDataContent.onlineCardPaymentExpiryText= json.LV885;
  appDataContent.onlineCardPaymentExpiredText= json.LV896;
  appDataContent.onlineCardPaymentExpiredErrorMessage= json.LV897;

  // ========================================================
  // Settings Section
  // ========================================================

  // Terms & Conditions Page
  appDataContent.termsConditionsBarTitle= json.LV252;

  // US Change Account Info Page
  appDataContent.usChangeAccountSaveChangesTxtBtn= json.LV253;
  appDataContent.usChangeAccountFirstNameBoxHeaderTxt= json.LV254;
  appDataContent.usChangeAccountLastNameBoxHeaderTxt= json.LV255;
  appDataContent.usChangeAccountPhoneNumberBoxHeaderTxt= json.LV256;
  appDataContent.usChangeAccountDateOfBirthBoxHeaderTxt= json.LV257;
  appDataContent.usChangeAccountGenderBoxHeaderTxt= json.LV258;
  appDataContent.usChangeAccountEmailBoxHeaderTxt= json.LV259;
  appDataContent.usChangeAccountChangesSubmittedflushBarMsg= json.LV260; 
  appDataContent.usChangeAccountNoChangesSubmittedflushBarMsg= json.LV261; 
  appDataContent.usChangeAccountTroubleSavingChangeslushBarMsg= json.LV262;
  appDataContent.usChangeAccountPersonalUserInfoBoxHeaderTxt= json.LV263; 
  appDataContent.usChangeAccountContactUserInfoBoxHeaderTxt= json.LV264; 
  appDataContent.usChangeAccountPhoneNumberFixedBoxHeaderTxt= json.LV265; 
  appDataContent.usChangeAccountInstructionSubmitBtnBoxHeaderTxt= json.LV266; 
  appDataContent.usChangeAccountSettingsInfoBarTxt= json.LV267;
  appDataContent.usChangeAccountEmailPlaceholder= json.LV268;
  appDataContent.dobPlaceHolder= json.LV566;
  appDataContent.emptyFirstNameAndOrLastName= json.LV586;
  appDataContent.usChangeAccountDeleteAccountTxtBtn= json.LV646;
  appDataContent.usChangeAccountDeleteAccountDialogTitle= json.LV647;
  appDataContent.usChangeAccountDeleteAccountDialogContent= json.LV648.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.usChangeAccountPasswordSectionTitle= json.LV649;
  appDataContent.usChangeAccountPasswordSectionContent= json.LV650;
  appDataContent.usChangeAccountChangePasswordTxtBtn= json.LV651;
  appDataContent.invalidEmail= json.LV869;

  // User Settings Page
  appDataContent.usSettingsContactUsUCTxtBtn= json.LV269;
  appDataContent.usSettingsDeliverySettingsBoxHeaderTxt= json.LV270; 
  appDataContent.usSettingsOrdersSettingsBoxHeaderTxt= json.LV271; 
  appDataContent.usSettingsMoreDetailsSettingsBoxHeaderTxt= json.LV272; 
  appDataContent.usSettingsLoyaltyProgramBoxTitle= json.LV273; 
  appDataContent.usSettingsLoyaltyStatusBoxContent= json.LV274;
  appDataContent.usSettingsPrimaryDeliveryAddressTxtBtn= json.LV276; 
  appDataContent.usSettingsSelectedStoreTxtBtn= json.LV277;
  appDataContent.usSettingsLogoutTxtBtn= json.LV278; 
  appDataContent.usSettingsContactUsTxtBtn= json.LV279; 
  appDataContent.usSettingsYourOrdersTxtBtn= json.LV280; 
  appDataContent.usSettingsPersonalInfoTxtBtn= json.LV281; 
  appDataContent.usSettingsAboutUsTxtBtn= json.LV282; 
  appDataContent.usSettingsTermsAndConditionsTxtBtn= json.LV283; 
  appDataContent.usSettingsChangingStoreByDefaultNotPossibleDialogContent= json.LV284;
  appDataContent.usSettingsChangingAddressDialogTitle= json.LV285;
  appDataContent.usSettingsChangingAddressDialogContent= json.LV286;
  appDataContent.usSettingsLogoutDialogTitle= json.LV287;
  appDataContent.usSettingsLogoutDialogContent= json.LV288;
  appDataContent.usSettingsUsIsNotLoggedCheckOrderDialogTitle= json.LV289;
  appDataContent.usSettingsUsIsNotLoggedCheckOrderDialogContent= json.LV290;
  appDataContent.usSettingsDialogCancel= json.LV291;
  appDataContent.usSettingsDialogSubmitOk= json.LV292;
  appDataContent.usSettingsDialogSubmitYes= json.LV293;
  appDataContent.usSettingsLoginOrRegisterTxtBtn= json.LV294;
  appDataContent.usSettingsChangeAccountInfoTxtBtn= json.LV295;
  appDataContent.usSettingsPartnerLoyaltyTitleTxtSuffix= json.LV296;
  appDataContent.usSettingsPartnerLoyaltyInvalidStateSubTitle= json.LV298;
  appDataContent.usSettingsPartnerLoyaltyAddBtn= json.LV299; 
  appDataContent.usSettingsPartnerLoyaltyInvalidMessageTxt1= json.LV300; 
  appDataContent.usSettingsPartnerLoyaltyInvalidMessageTxt2= json.LV301; 
  appDataContent.usSettingsPartnerLoyaltyValidationStateSubTitle= json.LV302;
  appDataContent.usSettingsPartnerLoyaltyCreationStateSubTitle= json.LV303;
  appDataContent.usSettingsPartnerLoyaltyValidationMessageTxt1= json.LV304; 
  appDataContent.usSettingsPartnerLoyaltyValidationMessageTxt2= json.LV305;  
  appDataContent.usSettingsPartnerLoyaltyCreationMessageTxt= json.LV306;
  appDataContent.usSettingsPartnerLoyaltyLastUpdateOnPrefix= json.LV307;
  appDataContent.usSettingsPartnerLoyaltyCardNbPrefix= json.LV308;
  appDataContent.usSettingsPartnerLoyaltyRemoveBtn= json.LV309; 
  appDataContent.usSettingsPartnerLoyaltyRemoveDialogTitle= json.LV310; 
  appDataContent.usSettingsPartnerLoyaltyRemoveDialogSubTitle= json.LV311; 
  appDataContent.usSettingsPartnerLoyaltyErrorOnDetachBnr= json.LV312; 
  appDataContent.partnerLoyaltyCurrency= json.LV511; 
  appDataContent.partnerLoyaltyUnit= json.LV512; 
  appDataContent.privacyPolicy= json.LV524;
  appDataContent.usSettingsPaymentMethodTxtBtn= json.LV638;



  // Contact Us page
  appDataContent.contactUsAppBarTitle= json.LV313;
  appDataContent.contactUsSendMessageBtn= json.LV314;
  appDataContent.contactUsFullNameErrorMessage= json.LV315;
  appDataContent.contactUsPhoneNumberLengthErrorMessage= json.LV316;
  appDataContent.contactUsPhoneNumberContentErrorMessage= json.LV317;
  appDataContent.contactUsSubjectErrorMessage= json.LV318;
  appDataContent.contactUsMessageErrorMessage= json.LV319;
  appDataContent.contactUsCallTitlePart= json.LV320;
  appDataContent.contactUsFullnamePlaceholder= json.LV321;
  appDataContent.contactUsPhoneNumberPlaceholder= json.LV322;
  appDataContent.contactUsSubjectPlaceholder= json.LV323;
  appDataContent.contactUsMessagePlaceholder= json.LV324;
  appDataContent.contactUsMessageTitlePart= json.LV325;
  appDataContent.contactUsUsecasePhoneNbValidationPage= json.LV326;
  appDataContent.contactUsUsecasePhoneNbValidationPageForForgetPass= json.LV470;
  appDataContent.contactUsUsecaseLoginPage= json.LV327;
  appDataContent.contactUsUsecaseMainSettingsPage= json.LV328;
  appDataContent.contactUsUsecaseListOfStoreSettingsPage= json.LV329;
  appDataContent.contactUsUsecaseOrderSummaryPage = json.LV330;
  appDataContent.contactUsUsecasePlaceOrder = json.LV459;
  appDataContent.contactUsUsecaseEditOrderDisabled = json.LV464;
  appDataContent.contactUsUsecaseCancelOrderDisabled = json.LV465;
  appDataContent.contactUsUsecaseFromMarketplace = json.LV678;
  appDataContent.contactUsWhatsAppMsgPlaceOrderPart2 = json.LV460.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsServiceReplyNotReceivedMessage= json.LV331;
  appDataContent.contactUsWhatsAppTitle= json.LV332;
  appDataContent.contactUsWhatsAppMsgPhoneNbValidation= json.LV333.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgLogin= json.LV334.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgUserLoggedIn1= json.LV335.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgUserLoggedIn2= json.LV336.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgUserNotLoggedIn= json.LV337.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgOrderSummaryUserLoggedIn2= json.LV429.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgKnownPhoneNbValidation= json.LV430.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsWhatsAppMsgLoginKnowPhoneNb= json.LV431.replaceAll("\\n", "\n"); // appDataContent is used when the content data contains "\n"
  appDataContent.contactUsPreFilledSubjectForOrderSummary= json.LV432;
  appDataContent.contactUsPreFilledSubjectForNumberValidation= json.LV433;
  appDataContent.contactUsPreFilledSubjectForLogin= json.LV434;

  // Add Partner Loyalty Card page
  appDataContent.addPartnerLoyaltyAppBarTitle = json.LV338;
  appDataContent.cardNbHeaderTxt1 = json.LV339;
  appDataContent.cardNbHeaderTxt2 = json.LV340;
  appDataContent.cardNbPlaceHolderText = json.LV341;
  appDataContent.phoneNbHeaderTxt1 = json.LV342;
  appDataContent.phoneNbHeaderTxt2 = json.LV343;
  appDataContent.phoneNbPlaceHolderText = json.LV344;
  appDataContent.submitBtnText = json.LV345;
  appDataContent.errorCardNbText = json.LV346;
  appDataContent.errorPhoneNbText = json.LV347;
  appDataContent.requestPartnerLoyaltyTxt = json.LV348;
  appDataContent.requestPartnerLoyaltyBtn = json.LV349; 
  appDataContent.errorOnServerResponseOnSubmit = json.LV350; 
  appDataContent.errorOnServerResponseOnRequest = json.LV351; 

  // Store Details Page
  appDataContent.openingHoursTitle = json.LV493;
  appDataContent.closedOpeningHours = json.LV494;
  appDataContent.paymentMethodsTitle = json.LV504;  
  appDataContent.freedeliverymsgPrefix = json.LV519; 
  appDataContent.storeDetailsGenericAppBarTitle = json.LV532;
  appDataContent.searchResultText = json.LV910;


  // ========================================================
  // UI Section
  // ========================================================

  //99 SubCat List Item
  appDataContent.subCatListItemsQuantityTxt= json.LV352; 

  // Add to Cart Button Page
  appDataContent.addToCartTxtBtn= json.LV353; // add to cart button content

  // Out of stock container (replacement of add to cart button when item is oos)
  appDataContent.outOfStockTxt= json.LV354; 

  // Custom App Bar Page
  appDataContent.changingStoreDialogTitle= json.LV355;     // Used in many pages when clicking to change the selected store Dialog // used in 2 pages: User Settings page
  appDataContent.changingStoreDialogContent= json.LV356; // Used in many pages when clicking to change the selected store Dialog // used in 2 pages: User Settings page
  appDataContent.unknownCityTxt= json.LV357; // used in 2 pages: custom banner location  
  appDataContent.unknownAddressTxt= json.LV358;   // used in 2 pages: custom banner location

  // Custom Banner Location Page
  appDataContent.changeLocationTxtBtn= json.LV359; // button change text

  // Custom Banner Switch Page

  // Custom Bottom Nav Bar Page
  appDataContent.customBottomNavStoreTabItemName= json.LV360;
  appDataContent.customBottomNavCategoriesTabItemName= json.LV361;
  appDataContent.customBottomNavOrdersTabItemName= json.LV362;
  appDataContent.customBottomNavMoreTabItemName= json.LV363;

  // Custom Button Page
  // Custom Chip Store Selection Page
  // Custom Expandable Tile Page
  // Custom List View Skeleton Page
  // Custom Loading Button Page
  // Custom Nav Bar Page
  // Custom Page Navigator Page
  // Custom Place Search Component Page

  // Custom Prodcut Listing Page
  appDataContent.perKgtTxt= json.LV365; // used in 3 pages : Order Receipt & Product Detail page

  // Custom Search Delegate Page

  // Custom SubCat Card UI Page
  appDataContent.customSubCatCardUIIsTobaccoOrAlcohoolDialogSubmitTxt= json.LV366;
  appDataContent.isTobaccoDialogTitle= json.LV367;
  appDataContent.isTobaccoDialogContent= json.LV368;
  appDataContent.isAlcoholDialogTitle= json.LV369;
  appDataContent.isAlcoholDialogContent= json.LV370;

  // Custom Timer Widget Page

  // Custom UI Widget Page
  appDataContent.onGoingOrdersListBarTitle= json.LV371; // used in 2 pages : Main navigation page
  appDataContent.customUIWidegetDeliveryAdressCardDialogSubmitTxt= json.LV372;
  appDataContent.customUIWidegetDeliveryAdressCardDialogCancelTxt= json.LV373;
  appDataContent.customUIWidegetStartShoppinginCartEmptyViewTxtBtn= json.LV374;
  appDataContent.customUIWidegetStartShoppinginStoreEmptyViewTxtBtn= json.LV375;
  
  // Custom Web View Controller Page
  appDataContent.customWebViewControllerTermsAndConditionsBarTitle= json.LV376;

 // Custom Widgets Auth Page
  appDataContent.nextTxtBtn= json.LV377;
  appDataContent.errorLowerThanSpecificValueTxtField1 = json.LV378;
  appDataContent.errorLowerThanSpecificValueTxtField2 =  json.LV379;
  appDataContent.errorIsNotAlphaTxtField= json.LV380;
  appDataContent.errorMobileNbrLengthTxtField1 = json.LV381;
  appDataContent.errorMobileNbrLengthTxtField2 =  json.LV382;
  appDataContent.errorMobileNbrIsNotNumericTxtField= json.LV383;
  appDataContent.mobileNbrTxtField= json.LV384;
  appDataContent.mobilePrefixTxtField= json.LV385;
  appDataContent.registerMessageAndButtonMsg= json.LV673;
  appDataContent.registerMessageAndButtonBtn= json.LV674;
  appDataContent.loginMessageAndButtonMsg= json.LV675;
  appDataContent.loginMessageAndButtonBtn= json.LV676;
  appDataContent.authInvalidMobileNumberMsg= json.LV677;

  // Custom Country Sheet
  appDataContent.countrySheetTitle= json.LV671;
  appDataContent.countrySheetSearchHint= json.LV672;

  // ========================================================
  // Model Section
  // ========================================================

  // Address
  appDataContent.addressErrorOnDetectionTxt= json.LV386; 
  appDataContent.addressUnamedRoadTxt= json.LV387; 
  appDataContent.homeTag= json.LV549;
  appDataContent.workTag= json.LV550;
  appDataContent.schoolTag= json.LV551;
  appDataContent.otherTag= json.LV552;


  // Cart
  // Category
  // Order

  // Orders Status
  appDataContent.cancelledOrder= json.LV388;
  appDataContent.scheduledOrder= json.LV389;
  appDataContent.newOrder= json.LV390;
  appDataContent.ongoingOrder= json.LV391;
  appDataContent.awaitingForReplacements= json.LV392;
  appDataContent.replacementsReceived= json.LV393;
  appDataContent.replacementsTimeOut= json.LV394;
  appDataContent.collectionCompleted= json.LV395;
  appDataContent.readyForDelivery= json.LV396;
  appDataContent.readyForPickup= json.LV397;
  appDataContent.inDeliveryOrder= json.LV398;
  appDataContent.delivered= json.LV399;
  appDataContent.pickupDone= json.LV400;
  appDataContent.completedOrder= json.LV401;
  appDataContent.cancelledOrderStatusInfo= json.LV402;
  appDataContent.newOrderStatusInfo= json.LV403;
  appDataContent.ongoingOrderStatusInfo= json.LV404;
  appDataContent.awaitingForReplStatusInfo= json.LV405;
  appDataContent.replacementsReceivedStatusInfo= json.LV406;
  appDataContent.replacementsTimeOutStatusInfo= json.LV407;
  appDataContent.collectionCompletedStatusInfo= json.LV408;
  appDataContent.readyForDeliveryOrderStatusInfo= json.LV409;
  appDataContent.readyForPickupOrderStatusInfo= json.LV410;
  appDataContent.inDeliveryOrderStatusInfo= json.LV411;
  appDataContent.deliveredStatusInfo= json.LV412;
  appDataContent.pickupDoneStatusInfo= json.LV413;
  appDataContent.completedOrderStatusInfo= json.LV414;
  appDataContent.allGrpStatus= json.LV447;
  appDataContent.scheduledGrpStatus= json.LV448;
  appDataContent.ongoingGrpStatus= json.LV449;
  appDataContent.completedGrpStatus= json.LV450;
  appDataContent.cancelledGrpStatus= json.LV451;

  
  // Order Types
  appDataContent.orderTypeUnknownTitle= json.LV415;
  appDataContent.orderTypeDeliveryTitle= json.LV416;
  appDataContent.orderTypePickupTitle= json.LV417;
  appDataContent.orderTypeInStoreTitle= json.LV418;
  appDataContent.orderTypeOtherTitle= json.LV419;



  // Payment Method
  appDataContent.cashPayment= json.LV420;
  appDataContent.ccPayment= json.LV421;
  appDataContent.unknownPaymentMethod= json.LV422;
  appDataContent.cashPaymentOption= json.LV423;
  appDataContent.ccPaymentOption= json.LV424;
  appDataContent.payCashOnDeliveryTerms= json.LV425;
  appDataContent.payCCOnDeliveryTerms= json.LV426;
  appDataContent.payCashOnPickupTerms= json.LV427;
  appDataContent.payCCOnPickupTerms= json.LV428;
  appDataContent.OnlineCCPayment= json.LV618;
  appDataContent.onlineCCPaymentOption= json.LV619;
  appDataContent.onlineCCTerms= json.LV620;
  appDataContent.mbOnDeliveryPayment= json.LV621;
  appDataContent.mbOnDeliveryPaymentOption= json.LV622;
  appDataContent.mbOnDeliveryTerms= json.LV623;
  appDataContent.mbOnPickupPayment= json.LV624;
  appDataContent.mbOnPickupPaymentOption= json.LV625;
  appDataContent.mbOnPickupTerms= json.LV626;


  // E-Wallet
  appDataContent.eWalletBalanceText = json.LV916;
  appDataContent.eWalletCashText = json.LV934;
  appDataContent.eWalletCheckoutBalanceText = json.LV935;
  appDataContent.eWalletPaidByText = json.LV944;
  appDataContent.eWalletRemainingToBePaid = "Remaining to be paid"; // json.LV944;
  appDataContent.eWalletNoTransactions = json.LV930;

  // Loyalty Program
  appDataContent.loyaltyProgramTitleText = json.LV818;
  appDataContent.usSettingsPartnerLoyaltyInitialStateSubTitle = json.LV297;
  appDataContent.usSettingsLoyaltyProgramDescriptionBoxContent= json.LV275;
  appDataContent.usSettingsLoyaltyProgramAddBtn = "Add";
  appDataContent.usSettingsLoyaltyProgramLastUpdatedOn = json.LV307;
  appDataContent.usSettingsLoyaltyProgramPoints = json.LV824;
  appDataContent.usSettingsLoyaltyProgramInvalidCard = json.LV820;
  appDataContent.usSettingsLoyaltyProgramAddLoyaltyCardNumberText = json.LV789;
  appDataContent.usSettingsLoyaltyProgramAddLoyaltyCardPhoneNumberText = json.LV790;
  appDataContent.usSettingsLoyaltyProgramAddLoyaltyPendingValidation = json.LV821;
  appDataContent.loyaltyCheckoutTitle = json.LV951;
  appDataContent.paidByLoyalty = json.LV954;


  appDataContent.otherPaymentMethodsTitleText = "Other Payment Methods"

  appDataContent.registrationAppMigrationText = json.LV948;
  appDataContent.registrationNotConfirmedAccount = json.LV949;
  appDataContent.orderETAText = json.LV757;
  appDataContent.orderIdText = json.LV732;

  appDataContent.promotedItemsPercentageText = json.LV781;
  appDataContent.BCRSText = json.LV1063;

  // ========================================================
  // Server requests
  // ========================================================
  appDataContent.serverRequestError= json.LV525;

    appDataContent.cartMinimumOrderAmoutText = json.LV773;
    appDataContent.cartGetFreeDeliveryText = json.LV774;

        return appDataContent;
    }
}